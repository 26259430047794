/* eslint-disable @typescript-eslint/ban-types */
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {
  [key: string]: unknown;
}> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends {
  [key: string]: unknown;
}, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(("/api/graphql" as string), {
      method: "POST",
      ...{
        "headers": {
          "Content-Type": "application/json"
        }
      },
      body: JSON.stringify({
        query,
        variables
      })
    });
    const json = await res.json();
    if (json.errors) {
      const {
        message
      } = json.errors[0];
      throw new Error(message);
    }
    return json.data;
  };
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {
    input: string;
    output: string;
  };
  String: {
    input: string;
    output: string;
  };
  Boolean: {
    input: boolean;
    output: boolean;
  };
  Int: {
    input: number;
    output: number;
  };
  Float: {
    input: number;
    output: number;
  };
  DateTime: {
    input: any;
    output: any;
  };
  JSON: {
    input: any;
    output: any;
  };
};
export enum AccentColor {
  Blue = 'BLUE',
  Gold = 'GOLD',
  Gray = 'GRAY',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Salmon = 'SALMON',
  Turquoise = 'TURQUOISE',
  Violet = 'VIOLET',
  Yellow = 'YELLOW',
}
export type Account = {
  __typename?: 'Account';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  providerAccountId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
};
export type AgeDataFiliter = {
  dobAge?: InputMaybe<Scalars['String']['input']>;
  dobCompare?: InputMaybe<Dobcompare>;
  gender?: InputMaybe<Gender>;
};
export enum Category {
  Hbcu = 'HBCU',
  HispanicServing = 'HISPANIC_SERVING',
  NaiaDivisionI = 'NAIA_DIVISION_I',
  NaiaDivisionIi = 'NAIA_DIVISION_II',
  NcaaDivisionI = 'NCAA_DIVISION_I',
  NcaaDivisionIi = 'NCAA_DIVISION_II',
  NcaaDivisionIii = 'NCAA_DIVISION_III',
  TribalCollege = 'TRIBAL_COLLEGE',
}
export type CreateOrganizationInput = {
  accentColor?: InputMaybe<AccentColor>;
  address?: InputMaybe<Array<Scalars['String']['input']>>;
  administrativeArea?: InputMaybe<Scalars['String']['input']>;
  alternateContact?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<Category>>;
  contactPerson?: InputMaybe<Scalars['JSON']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  helpDesk?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Location>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<PermissionTier>;
  portalText?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<PrimaryColor>;
  size?: InputMaybe<Size>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrgStatus>;
  types?: InputMaybe<Array<Type>>;
  website?: InputMaybe<Scalars['String']['input']>;
};
export type CreateUserInput = {
  acquisition?: InputMaybe<Scalars['String']['input']>;
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role: Role;
  roleElevations?: InputMaybe<Array<RoleElevation>>;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
  settings?: InputMaybe<SettingsInput>;
  sex?: InputMaybe<Sex>;
  status: UserStatus;
};
export enum Dobcompare {
  Above = 'ABOVE',
  Under = 'UNDER',
}
export type DashboardData = {
  __typename?: 'DashboardData';
  administratorsInvited: Scalars['Int']['output'];
  administratorsRegistered: Scalars['Int']['output'];
  averageTimeOnPlatform: Scalars['Float']['output'];
  countAllOrganizations: Scalars['Int']['output'];
  studentsComplete: Scalars['Int']['output'];
  studentsExempt: Scalars['Int']['output'];
  studentsInProgress: Scalars['Int']['output'];
  studentsRegistered: Scalars['Int']['output'];
  studentsRostered: Scalars['Int']['output'];
};
export type ExperienceQ07 = {
  __typename?: 'ExperienceQ07';
  LONG_AGO: StudentsExperienceResult;
  MONTH: StudentsExperienceResult;
  NEVER: StudentsExperienceResult;
  YEAR: StudentsExperienceResult;
};
export enum FileType {
  Image = 'IMAGE',
  Pdf = 'PDF',
  Video = 'VIDEO',
  Website = 'WEBSITE',
}
export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Nonbinary = 'NONBINARY',
}
export type InvitationDetail = {
  __typename?: 'InvitationDetail';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<StudentPermission>;
};
export type Invite = {
  __typename?: 'Invite';
  disciplined?: Maybe<Scalars['Boolean']['output']>;
  dob?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  exempt?: Maybe<Scalars['Boolean']['output']>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  role?: Maybe<Role>;
  schoolYear?: Maybe<Scalars['Int']['output']>;
};
export type InviteInput = {
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  role: Role;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
};
export type InvitedStudentsDetailInput = {
  invitedIds: Array<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export enum Location {
  LargeCity = 'LARGE_CITY',
  LargeTown = 'LARGE_TOWN',
  RuralCommunity = 'RURAL_COMMUNITY',
  SmallCity = 'SMALL_CITY',
  SmallTown = 'SMALL_TOWN',
  VeryLargeCity = 'VERY_LARGE_CITY',
}
export type Metrics = {
  __typename?: 'Metrics';
  belowAverageCount: Scalars['Int']['output'];
  dashboardData: DashboardData;
  expressConcernCount: Scalars['Int']['output'];
  negativeEffectCount: NegativeEffectCount;
  positiveEffectCount: PositiveEffectCount;
  questionAnswerCountData: QuestionAnswerCountData;
  questionCountData: QuestionCountData;
  riskFactorsCount: Scalars['Int']['output'];
  soberNegativeCount: SoberNegativeCount;
  studentsExperienceData: StudentsExperienceData;
  virtualBarAverageTime: Scalars['Float']['output'];
  virtualBarRevisit: VirtualBarRevisit;
};
export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  archiveNotification?: Maybe<Notification>;
  archiveNotifications?: Maybe<Scalars['Int']['output']>;
  archiveOrganization?: Maybe<Organization>;
  archiveOrganizations: Scalars['Int']['output'];
  completeSectionForUserLessonProgress?: Maybe<UserLessonProgress>;
  createInvite?: Maybe<Invite>;
  createManyInvites?: Maybe<Array<Maybe<Invite>>>;
  createNotification?: Maybe<Notification>;
  createOrganization?: Maybe<Organization>;
  createPing?: Maybe<UserPing>;
  createResource?: Maybe<Resource>;
  createUser?: Maybe<User>;
  createUserLessonProgress?: Maybe<UserLessonProgress>;
  createUserLessonProgresses?: Maybe<Scalars['Int']['output']>;
  createUserOrganization?: Maybe<UserOrganization>;
  createVerificationRequest?: Maybe<VerificationRequest>;
  deleteAllOrganizationsOfUser?: Maybe<Scalars['Int']['output']>;
  deleteInvite?: Maybe<Invite>;
  deleteInvites?: Maybe<Scalars['Int']['output']>;
  deleteNotification?: Maybe<Notification>;
  deleteResource?: Maybe<Resource>;
  deleteUser?: Maybe<User>;
  deleteUserOrganization?: Maybe<UserOrganization>;
  deleteUsers?: Maybe<Scalars['Int']['output']>;
  joinSecondaryOrganization?: Maybe<OrganizationOfStudent>;
  markProgressAsComplete?: Maybe<UserLessonProgress>;
  restoreNotifications?: Maybe<Scalars['Int']['output']>;
  restoreUser?: Maybe<User>;
  restoreUsers?: Maybe<Scalars['Int']['output']>;
  unarchiveOrganization?: Maybe<Organization>;
  unarchiveOrganizations: Scalars['Int']['output'];
  updateInvite?: Maybe<Invite>;
  updateInvites?: Maybe<Scalars['Int']['output']>;
  updateNotification?: Maybe<Notification>;
  updateOrganization?: Maybe<Organization>;
  updateOrganizationGroups?: Maybe<Organization>;
  updateResource?: Maybe<Resource>;
  updateResourceOrder?: Maybe<Organization>;
  updateUser?: Maybe<User>;
  updateUserLessonAnswer: UserLessonAnswer;
  updateUserOrganization?: Maybe<UserOrganization>;
  updateUsers?: Maybe<Scalars['Int']['output']>;
};
export type MutationArchiveNotificationArgs = {
  id: Scalars['Int']['input'];
};
export type MutationArchiveNotificationsArgs = {
  ids: Array<Scalars['Int']['input']>;
};
export type MutationArchiveOrganizationArgs = {
  id: Scalars['String']['input'];
};
export type MutationArchiveOrganizationsArgs = {
  ids: Array<Scalars['String']['input']>;
};
export type MutationCompleteSectionForUserLessonProgressArgs = {
  sectionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export type MutationCreateInviteArgs = {
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  user: InviteInput;
};
export type MutationCreateManyInvitesArgs = {
  users: Array<InviteInput>;
};
export type MutationCreateNotificationArgs = {
  data: NotificationInfo;
};
export type MutationCreateOrganizationArgs = {
  data: CreateOrganizationInput;
};
export type MutationCreatePingArgs = {
  data: UserPingInfo;
};
export type MutationCreateResourceArgs = {
  data: ResourceInput;
  name: Scalars['String']['input'];
};
export type MutationCreateUserArgs = {
  data: CreateUserInput;
};
export type MutationCreateUserLessonProgressArgs = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};
export type MutationCreateUserLessonProgressesArgs = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};
export type MutationCreateUserOrganizationArgs = {
  data: UserOrganizationInput;
};
export type MutationCreateVerificationRequestArgs = {
  expires: Scalars['DateTime']['input'];
  identifier: Scalars['String']['input'];
  token: Scalars['String']['input'];
};
export type MutationDeleteAllOrganizationsOfUserArgs = {
  userId: Scalars['String']['input'];
};
export type MutationDeleteInviteArgs = {
  email: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationDeleteInvitesArgs = {
  emails: Array<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationDeleteNotificationArgs = {
  id: Scalars['Int']['input'];
};
export type MutationDeleteResourceArgs = {
  id: Scalars['String']['input'];
};
export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationDeleteUserOrganizationArgs = {
  id: Scalars['String']['input'];
};
export type MutationDeleteUsersArgs = {
  ids: Array<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationJoinSecondaryOrganizationArgs = {
  orgId: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export type MutationMarkProgressAsCompleteArgs = {
  userId: Scalars['String']['input'];
};
export type MutationRestoreNotificationsArgs = {
  ids: Array<Scalars['Int']['input']>;
};
export type MutationRestoreUserArgs = {
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationRestoreUsersArgs = {
  ids: Array<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationUnarchiveOrganizationArgs = {
  id: Scalars['String']['input'];
};
export type MutationUnarchiveOrganizationsArgs = {
  ids: Array<Scalars['String']['input']>;
};
export type MutationUpdateInviteArgs = {
  data: UpdateInviteInput;
  email: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationUpdateInvitesArgs = {
  data: UpdateInviteInput;
  emails: Array<Scalars['String']['input']>;
  groupPush?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationUpdateNotificationArgs = {
  data: NotificationInfo;
  id: Scalars['Int']['input'];
};
export type MutationUpdateOrganizationArgs = {
  data: UpdateOrganizationInput;
  id: Scalars['String']['input'];
};
export type MutationUpdateOrganizationGroupsArgs = {
  id: Scalars['String']['input'];
  newGroups: Array<Scalars['String']['input']>;
};
export type MutationUpdateResourceArgs = {
  data: ResourceInput;
  id: Scalars['String']['input'];
};
export type MutationUpdateResourceOrderArgs = {
  id: Scalars['String']['input'];
  orderList: Scalars['JSON']['input'];
};
export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationUpdateUserLessonAnswerArgs = {
  answerPayload: Scalars['JSON']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  progressId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export type MutationUpdateUserOrganizationArgs = {
  data: UserOrganizationInput;
  id: Scalars['String']['input'];
};
export type MutationUpdateUsersArgs = {
  data: UpdateUserInput;
  groupPush?: InputMaybe<Scalars['Boolean']['input']>;
  ids: Array<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type NegativeEffectCount = {
  __typename?: 'NegativeEffectCount';
  BAD_DECISION: Scalars['Int']['output'];
  BLACKOUT: Scalars['Int']['output'];
  BROWNOUT: Scalars['Int']['output'];
  DEPRESSED: Scalars['Int']['output'];
  DISAPPOINTED: Scalars['Int']['output'];
  DROVE: Scalars['Int']['output'];
  EMBARRASSED: Scalars['Int']['output'];
  GRADES: Scalars['Int']['output'];
  HANGOVER: Scalars['Int']['output'];
  HURT: Scalars['Int']['output'];
  PASSED_OUT: Scalars['Int']['output'];
  POLICE_TROUBLE: Scalars['Int']['output'];
  PROPERTY: Scalars['Int']['output'];
  SCHOOL_TROUBLE: Scalars['Int']['output'];
  SEX: Scalars['Int']['output'];
  SOCIAL_MEDIA: Scalars['Int']['output'];
  SUICIDE: Scalars['Int']['output'];
};
export type Notification = {
  __typename?: 'Notification';
  audience: Array<NotificationAudience>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateToSend?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deliveryType: Array<NotificationType>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  seenList: Array<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};
export enum NotificationAudience {
  AllStudents = 'ALL_STUDENTS',
  OrgManagers = 'ORG_MANAGERS',
  OrgOwners = 'ORG_OWNERS',
  PrivateOrgManagers = 'Private_ORG_MANAGERS',
}
export type NotificationFilterInput = {
  audience?: InputMaybe<Array<NotificationAudience>>;
  deliveryType?: InputMaybe<Array<NotificationType>>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};
export type NotificationInfo = {
  audience: Array<NotificationAudience>;
  body?: InputMaybe<Scalars['String']['input']>;
  dateToSend?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryType: Array<NotificationType>;
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  seenList?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};
export enum NotificationType {
  Email = 'EMAIL',
  InApp = 'IN_APP',
}
export enum OrgStatus {
  OrgActive = 'ORG_ACTIVE',
  OrgArchived = 'ORG_ARCHIVED',
  OrgPending = 'ORG_PENDING',
}
export type Organization = {
  __typename?: 'Organization';
  accentColor: AccentColor;
  address?: Maybe<Array<Scalars['String']['output']>>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  alternateContact?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<Category>>;
  contactPerson?: Maybe<Scalars['JSON']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  domains?: Maybe<Array<Scalars['String']['output']>>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  helpDesk?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locality?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  permission: PermissionTier;
  portalText?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  primaryColor: PrimaryColor;
  resource?: Maybe<Array<Resource>>;
  resourceOrderKey?: Maybe<Scalars['JSON']['output']>;
  size?: Maybe<Size>;
  slug?: Maybe<Scalars['String']['output']>;
  status: OrgStatus;
  types?: Maybe<Array<Type>>;
  userOrganization?: Maybe<Array<UserOrganization>>;
  website?: Maybe<Scalars['String']['output']>;
};
export type OrganizationFilter = {
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<Array<PermissionTier>>;
  size?: InputMaybe<Array<Size>>;
  status?: InputMaybe<Array<OrgStatus>>;
  website?: InputMaybe<Scalars['String']['input']>;
};
export type OrganizationOfStudent = {
  __typename?: 'OrganizationOfStudent';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isJoined: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};
export type PaginatedOrgList = {
  __typename?: 'PaginatedOrgList';
  allOrgs?: Maybe<Array<Organization>>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  paginatedOrgList?: Maybe<Array<Organization>>;
  pendingOrgs?: Maybe<Array<Organization>>;
  previousPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalActive?: Maybe<Scalars['Int']['output']>;
  totalArchived?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};
export type PaginatedUserList = {
  __typename?: 'PaginatedUserList';
  allUsers?: Maybe<Array<Maybe<User>>>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  paginatedUserList?: Maybe<Array<Maybe<User>>>;
  previousPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};
export type PaginationInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};
export enum PermissionTier {
  FirstTier = 'FIRST_TIER',
  FourthTier = 'FOURTH_TIER',
  SecondTier = 'SECOND_TIER',
  ThirdTier = 'THIRD_TIER',
}
export type PositiveEffectCount = {
  __typename?: 'PositiveEffectCount';
  ACCOMPLISHED: Scalars['Int']['output'];
  FEEL: Scalars['Int']['output'];
  FUN: Scalars['Int']['output'];
  MEET: Scalars['Int']['output'];
  RELAX: Scalars['Int']['output'];
  SOCIALIZE: Scalars['Int']['output'];
  TASTE: Scalars['Int']['output'];
};
export type PrePostAnswerCount = {
  __typename?: 'PrePostAnswerCount';
  post?: Maybe<Scalars['Int']['output']>;
  pre?: Maybe<Scalars['Int']['output']>;
};
export type PrePostBystanderCount = {
  __typename?: 'PrePostBystanderCount';
  CALL: PrePostAnswerCount;
  OFFER: PrePostAnswerCount;
  RECRUIT: PrePostAnswerCount;
  REMOVE: PrePostAnswerCount;
  WAIT: PrePostAnswerCount;
};
export type PrePostChallengedCount = {
  __typename?: 'PrePostChallengedCount';
  NO: PrePostAnswerCount;
  UNSURE: PrePostAnswerCount;
  YES: PrePostAnswerCount;
};
export type PrePostEnjoyedCount = {
  __typename?: 'PrePostEnjoyedCount';
  NO: PrePostAnswerCount;
  UNSURE: PrePostAnswerCount;
  YES: PrePostAnswerCount;
};
export type PrePostEquippedCount = {
  __typename?: 'PrePostEquippedCount';
  NO: PrePostAnswerCount;
  UNSURE: PrePostAnswerCount;
  YES: PrePostAnswerCount;
};
export type PrePostHarmfulCount = {
  __typename?: 'PrePostHarmfulCount';
  F2M3: PrePostAnswerCount;
  F4M4: PrePostAnswerCount;
  F4M5: PrePostAnswerCount;
  F5M6: PrePostAnswerCount;
};
export type PrePostMetrics = {
  __typename?: 'PrePostMetrics';
  bystanderCount: PrePostBystanderCount;
  challengedCount: PrePostChallengedCount;
  enjoyedCount: PrePostEnjoyedCount;
  equippedCount: PrePostEquippedCount;
  harmfulCount: PrePostHarmfulCount;
  partyCount: PrePostPartyCount;
  protectiveCount: PrePostProtectiveCount;
  questionCountData: PrePostQuestionCountData;
  riskyCount: PrePostRiskyCount;
};
export type PrePostPartyCount = {
  __typename?: 'PrePostPartyCount';
  FOOD: PrePostAnswerCount;
  MANAGE_CROWD: PrePostAnswerCount;
  OPEN_CONTAINERS: PrePostAnswerCount;
  PLAN_AHEAD: PrePostAnswerCount;
};
export type PrePostProtectiveCount = {
  __typename?: 'PrePostProtectiveCount';
  ALTERNATE: PrePostAnswerCount;
  BUDDY: PrePostAnswerCount;
  FOOD: PrePostAnswerCount;
  NO_DRUGS: PrePostAnswerCount;
  OTHER: PrePostAnswerCount;
  PACE: PrePostAnswerCount;
  POSESS: PrePostAnswerCount;
  PREDETERMINE: PrePostAnswerCount;
  SELF_MAKE: PrePostAnswerCount;
  TRACK: PrePostAnswerCount;
};
export type PrePostQuestionCountData = {
  __typename?: 'PrePostQuestionCountData';
  Q01: Scalars['Int']['output'];
  Q31: Scalars['Int']['output'];
  Q32: Scalars['Int']['output'];
  Q33: Scalars['Int']['output'];
  Q34: Scalars['Int']['output'];
  Q35: Scalars['Int']['output'];
  Q36: Scalars['Int']['output'];
  Q37: Scalars['Int']['output'];
  Q38: Scalars['Int']['output'];
};
export type PrePostRiskyCount = {
  __typename?: 'PrePostRiskyCount';
  LESS: PrePostAnswerCount;
  MORE: PrePostAnswerCount;
  NEITHER: PrePostAnswerCount;
  UNSURE: PrePostAnswerCount;
};
export enum Precedence {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}
export enum PrimaryColor {
  Blue = 'BLUE',
  Fuchsia = 'FUCHSIA',
  Gold = 'GOLD',
  Green = 'GREEN',
  Red = 'RED',
  Slategray = 'SLATEGRAY',
  Steelblue = 'STEELBLUE',
  Teal = 'TEAL',
  Violet = 'VIOLET',
}
export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  allOrgManagers: Array<Maybe<User>>;
  allOrgOwners: Array<Maybe<User>>;
  allOrganizations: Array<Maybe<Organization>>;
  allOrgsForSystem?: Maybe<PaginatedOrgList>;
  allRegisteredAndInvitedOrgAdmins: PaginatedUserList;
  allRegisteredAndInvitedSystemAdmins: PaginatedUserList;
  allStudents: Array<Maybe<User>>;
  filteredStudentsForExcel?: Maybe<Array<Maybe<StudentForExcel>>>;
  getInvitedStudentsDetail?: Maybe<Array<InvitationDetail>>;
  getLatestJoinedOrganizationsWithDetails?: Maybe<Array<InvitationDetail>>;
  getLatestVerificationRequest?: Maybe<VerificationRequest>;
  getOrganizationsByUserId?: Maybe<Array<Maybe<Organization>>>;
  getPrimaryResources?: Maybe<Array<Resource>>;
  getStudentMyGroupFilter?: Maybe<Array<Scalars['String']['output']>>;
  invitedByEmail?: Maybe<Array<Maybe<Invite>>>;
  metrics?: Maybe<Metrics>;
  notification?: Maybe<Notification>;
  notificationsByFilter?: Maybe<Array<Notification>>;
  notificationsByStatus?: Maybe<Array<Notification>>;
  organization?: Maybe<Organization>;
  organizationBySlug?: Maybe<Organization>;
  organizations: Array<Maybe<Organization>>;
  organizationsByFilter: Array<Maybe<Organization>>;
  prePostMetrics?: Maybe<PrePostMetrics>;
  registeredAndInvitedAdminsByOrganization: PaginatedUserList;
  registeredAndInvitedStudentsByOrganization: PaginatedUserList;
  registeredAndInvitedUsers: Array<Maybe<User>>;
  resource?: Maybe<Resource>;
  resourcesByOrgId?: Maybe<Array<Resource>>;
  studentOrgsjoinedAndInvited: Array<Maybe<OrganizationOfStudent>>;
  user?: Maybe<User>;
  userByEmail?: Maybe<User>;
  userInvites: Array<Maybe<Invite>>;
  userLessonAnswer?: Maybe<UserLessonAnswer>;
  userLessonAnswersByCompoundId?: Maybe<Array<Maybe<UserLessonAnswer>>>;
  userLessonProgress: Array<Maybe<UserLessonProgress>>;
  userPings?: Maybe<Array<Maybe<UserPing>>>;
  users: Array<Maybe<User>>;
};
export type QueryAllOrgsForSystemArgs = {
  filters?: InputMaybe<SystemOrgFilter>;
  paginationData?: InputMaybe<PaginationInput>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
};
export type QueryAllRegisteredAndInvitedOrgAdminsArgs = {
  filters?: InputMaybe<Array<Scalars['String']['input']>>;
  paginationData?: InputMaybe<PaginationInput>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
};
export type QueryAllRegisteredAndInvitedSystemAdminsArgs = {
  paginationData?: InputMaybe<PaginationInput>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
};
export type QueryFilteredStudentsForExcelArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};
export type QueryGetInvitedStudentsDetailArgs = {
  params: InvitedStudentsDetailInput;
};
export type QueryGetLatestJoinedOrganizationsWithDetailsArgs = {
  orgId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export type QueryGetLatestVerificationRequestArgs = {
  identifier: Scalars['String']['input'];
};
export type QueryGetOrganizationsByUserIdArgs = {
  userId: Scalars['String']['input'];
};
export type QueryGetStudentMyGroupFilterArgs = {
  orgId: Scalars['String']['input'];
};
export type QueryInvitedByEmailArgs = {
  email: Scalars['String']['input'];
};
export type QueryMetricsArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};
export type QueryNotificationArgs = {
  id: Scalars['Int']['input'];
};
export type QueryNotificationsByFilterArgs = {
  filter: NotificationFilterInput;
};
export type QueryNotificationsByStatusArgs = {
  isDraft: Scalars['Boolean']['input'];
  orgId: Scalars['String']['input'];
};
export type QueryOrganizationArgs = {
  id: Scalars['String']['input'];
};
export type QueryOrganizationBySlugArgs = {
  slug: Scalars['String']['input'];
};
export type QueryOrganizationsArgs = {
  ids: Array<Scalars['String']['input']>;
};
export type QueryOrganizationsByFilterArgs = {
  filter: OrganizationFilter;
};
export type QueryPrePostMetricsArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};
export type QueryRegisteredAndInvitedAdminsByOrganizationArgs = {
  orgId: Scalars['String']['input'];
  paginationData?: InputMaybe<PaginationInput>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
};
export type QueryRegisteredAndInvitedStudentsByOrganizationArgs = {
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['String']['input'];
  paginationData?: InputMaybe<PaginationInput>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
};
export type QueryRegisteredAndInvitedUsersArgs = {
  ids: Array<Scalars['String']['input']>;
};
export type QueryResourceArgs = {
  id: Scalars['String']['input'];
};
export type QueryResourcesByOrgIdArgs = {
  orgId: Scalars['String']['input'];
};
export type QueryStudentOrgsjoinedAndInvitedArgs = {
  userEmail: Scalars['String']['input'];
};
export type QueryUserArgs = {
  id: Scalars['String']['input'];
};
export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};
export type QueryUserInvitesArgs = {
  emails: Array<Scalars['String']['input']>;
};
export type QueryUserLessonAnswerArgs = {
  progressId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export type QueryUserLessonAnswersByCompoundIdArgs = {
  progressId: Scalars['String']['input'];
  questionIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};
export type QueryUserLessonProgressArgs = {
  userId: Scalars['String']['input'];
};
export type QueryUserPingsArgs = {
  userId: Scalars['String']['input'];
};
export type QueryUsersArgs = {
  ids: Array<Scalars['String']['input']>;
};
export type QuestionAnswerCountData = {
  __typename?: 'QuestionAnswerCountData';
  Q32: Scalars['Int']['output'];
};
export type QuestionCountData = {
  __typename?: 'QuestionCountData';
  Q07: Scalars['Int']['output'];
  Q13: Scalars['Int']['output'];
  Q14: Scalars['Int']['output'];
  Q14a: Scalars['Int']['output'];
  Q14b: Scalars['Int']['output'];
  Q17: Scalars['Int']['output'];
  Q18: Scalars['Int']['output'];
  Q32: Scalars['Int']['output'];
};
export type Resource = {
  __typename?: 'Resource';
  fileType: FileType;
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  resourceType: ResourceType;
  thumbNail?: Maybe<Scalars['String']['output']>;
};
export type ResourceInput = {
  fileType: FileType;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  resourceType: ResourceType;
  thumbNail?: InputMaybe<Scalars['String']['input']>;
};
export enum ResourceType {
  PrimaryResource = 'PRIMARY_RESOURCE',
  SecondaryResource = 'SECONDARY_RESOURCE',
}
export enum Role {
  Developer = 'DEVELOPER',
  OrgManager = 'ORG_MANAGER',
  OrgOwner = 'ORG_OWNER',
  Student = 'STUDENT',
  SystemManager = 'SYSTEM_MANAGER',
  SystemOwner = 'SYSTEM_OWNER',
}
export enum RoleElevation {
  ManageOrgAccount = 'MANAGE_ORG_ACCOUNT',
  ManageOrgAdmins = 'MANAGE_ORG_ADMINS',
  ManageOrgStudents = 'MANAGE_ORG_STUDENTS',
  ViewMetrics = 'VIEW_METRICS',
  ViewOrgAccount = 'VIEW_ORG_ACCOUNT',
  ViewOrgStudents = 'VIEW_ORG_STUDENTS',
}
export type Session = {
  __typename?: 'Session';
  User?: Maybe<User>;
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sessionToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};
export type SessionStudent = {
  __typename?: 'SessionStudent';
  end?: Maybe<Scalars['DateTime']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
};
export type Settings = {
  __typename?: 'Settings';
  blockEmailNotifications?: Maybe<Scalars['Boolean']['output']>;
};
export type SettingsInput = {
  blockEmailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
};
export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE',
}
export enum Size {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  Xlarge = 'XLARGE',
  Xsmall = 'XSMALL',
  Xxlarge = 'XXLARGE',
}
export type SoberNegativeCount = {
  __typename?: 'SoberNegativeCount';
  BAD_DECISION: Scalars['Int']['output'];
  DEPRESSED: Scalars['Int']['output'];
  DISAPPOINTED: Scalars['Int']['output'];
  EMBARRASSED: Scalars['Int']['output'];
  GRADES: Scalars['Int']['output'];
  HURT: Scalars['Int']['output'];
  POLICE_TROUBLE: Scalars['Int']['output'];
  PROPERTY: Scalars['Int']['output'];
  SCHOOL_TROUBLE: Scalars['Int']['output'];
};
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}
export enum SortingKey {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}
export type StudentForExcel = {
  __typename?: 'StudentForExcel';
  acquisitionChannel?: Maybe<Scalars['String']['output']>;
  courseProgress?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  disciplined?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  engagedCourseTime?: Maybe<Scalars['String']['output']>;
  exempt?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  organizationCategory?: Maybe<Scalars['String']['output']>;
  organizationEnrollment?: Maybe<Scalars['String']['output']>;
  organizationLocation?: Maybe<Scalars['String']['output']>;
  organizationRegion?: Maybe<Scalars['String']['output']>;
  organizationState?: Maybe<Scalars['String']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  passiveCourseTime?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  roleElevations?: Maybe<Scalars['String']['output']>;
  schoolYear?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<Sex>;
  status?: Maybe<UserStatus>;
  userLessonProgress?: Maybe<Scalars['String']['output']>;
  userSessionStartsEnds?: Maybe<Scalars['String']['output']>;
};
export type StudentOfOrganization = {
  __typename?: 'StudentOfOrganization';
  editAccess?: Maybe<Scalars['Boolean']['output']>;
  student?: Maybe<User>;
};
export enum StudentPermission {
  FullPermission = 'FULL_PERMISSION',
  Limited = 'LIMITED',
}
export type StudentsExperienceData = {
  __typename?: 'StudentsExperienceData';
  experienceQ07: ExperienceQ07;
  experienceQ11: StudentsExperienceResult;
};
export type StudentsExperienceInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};
export type StudentsExperienceResult = {
  __typename?: 'StudentsExperienceResult';
  answer?: Maybe<Scalars['String']['output']>;
  female: Scalars['Int']['output'];
  grandTotal: Scalars['Int']['output'];
  male: Scalars['Int']['output'];
  nonBinary: Scalars['Int']['output'];
  over21: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  under21: Scalars['Int']['output'];
};
export type SystemOrgFilter = {
  category?: InputMaybe<Array<Category>>;
  permission?: InputMaybe<Array<PermissionTier>>;
  type?: InputMaybe<Array<Type>>;
};
export enum Type {
  FourYear = 'FOUR_YEAR',
  GreekChapter = 'GREEK_CHAPTER',
  GreekNational = 'GREEK_NATIONAL',
  TwoYear = 'TWO_YEAR',
}
export type UpdateInviteInput = {
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
};
export type UpdateOrganizationInput = {
  accentColor?: InputMaybe<AccentColor>;
  address?: InputMaybe<Array<Scalars['String']['input']>>;
  administrativeArea?: InputMaybe<Scalars['String']['input']>;
  alternateContact?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<Category>>;
  contactPerson?: InputMaybe<Scalars['JSON']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  helpDesk?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Location>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<PermissionTier>;
  portalText?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<PrimaryColor>;
  size?: InputMaybe<Size>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrgStatus>;
  types?: InputMaybe<Array<Type>>;
  website?: InputMaybe<Scalars['String']['input']>;
};
export type UpdateUserInput = {
  acquisition?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  roleElevations?: InputMaybe<Array<RoleElevation>>;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
  settings?: InputMaybe<SettingsInput>;
  sex?: InputMaybe<Sex>;
  status?: InputMaybe<UserStatus>;
};
export type User = {
  __typename?: 'User';
  acquisition: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  disciplined?: Maybe<Scalars['Boolean']['output']>;
  dob?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  editAccess?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['String']['output'];
  exempt?: Maybe<Scalars['Boolean']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notification?: Maybe<Array<Maybe<Notification>>>;
  orgId?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  phone?: Maybe<Scalars['String']['output']>;
  role: Role;
  roleElevations?: Maybe<Array<Maybe<RoleElevation>>>;
  schoolYear?: Maybe<Scalars['Int']['output']>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  settings: Settings;
  sex?: Maybe<Sex>;
  status: UserStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userLessonProgress?: Maybe<Array<Maybe<UserLessonProgress>>>;
  userOrganization?: Maybe<Array<Maybe<UserOrganization>>>;
  userPings?: Maybe<Array<Maybe<UserPing>>>;
};
export type UserFilterInput = {
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  precedence?: InputMaybe<Array<Precedence>>;
  role?: InputMaybe<Array<Role>>;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
  sex?: InputMaybe<Sex>;
  status?: InputMaybe<Array<UserOrgStatus>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};
export type UserLessonAnswer = {
  __typename?: 'UserLessonAnswer';
  answerPayload?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  progressId?: Maybe<Scalars['String']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  timeAnswered?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};
export type UserLessonProgress = {
  __typename?: 'UserLessonProgress';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  sectionsComplete?: Maybe<Array<Scalars['String']['output']>>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};
export enum UserOrgStatus {
  OrgArchived = 'ORG_ARCHIVED',
  OrgJoined = 'ORG_JOINED',
  OrgPending = 'ORG_PENDING',
}
export type UserOrganization = {
  __typename?: 'UserOrganization';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  disciplined?: Maybe<Scalars['Boolean']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  exempt?: Maybe<Scalars['Boolean']['output']>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  precedence?: Maybe<Precedence>;
  schoolYear?: Maybe<Scalars['Int']['output']>;
  seenList?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<UserOrgStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};
export type UserOrganizationInput = {
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['String']['input'];
  precedence: Precedence;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
  seenList?: InputMaybe<Array<Scalars['String']['input']>>;
  status: UserOrgStatus;
  userId: Scalars['String']['input'];
};
export type UserPing = {
  __typename?: 'UserPing';
  createdAt: Scalars['DateTime']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  pageUrl?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['JSON']['output']>;
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};
export type UserPingInfo = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  pageUrl?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
  userId: Scalars['String']['input'];
};
export enum UserStatus {
  AdminActive = 'ADMIN_ACTIVE',
  AdminArchived = 'ADMIN_ARCHIVED',
  AdminInvited = 'ADMIN_INVITED',
  StudentActive = 'STUDENT_ACTIVE',
  StudentArchived = 'STUDENT_ARCHIVED',
  StudentComplete = 'STUDENT_COMPLETE',
  StudentExempt = 'STUDENT_EXEMPT',
  StudentInvited = 'STUDENT_INVITED',
}
export type VerificationRequest = {
  __typename?: 'VerificationRequest';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  identifier: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type VerificationRequestInput = {
  expires: Scalars['DateTime']['input'];
  identifier: Scalars['String']['input'];
  token: Scalars['String']['input'];
};
export type VirtualBarRevisit = {
  __typename?: 'VirtualBarRevisit';
  revisit: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};
export type VirtualBarStudentRevisit = {
  __typename?: 'VirtualBarStudentRevisit';
  revisit: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};
export type NotificationFragment = {
  __typename?: 'Notification';
  id: number;
  headline?: string | null;
  body?: string | null;
  isDraft?: boolean | null;
  audience: Array<NotificationAudience>;
  seenList: Array<string>;
  orgId?: string | null;
  userId?: string | null;
  deliveryType: Array<NotificationType>;
  dateToSend?: any | null;
  expirationDate?: any | null;
  createdAt?: any | null;
  deletedAt?: any | null;
};
export type InvitationDetailFragment = {
  __typename?: 'InvitationDetail';
  name?: string | null;
  email?: string | null;
  permission?: StudentPermission | null;
};
export type NotificationQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;
export type NotificationQuery = {
  __typename?: 'Query';
  notification?: {
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  } | null;
};
export type NotificationsByStatusQueryVariables = Exact<{
  isDraft: Scalars['Boolean']['input'];
  orgId: Scalars['String']['input'];
}>;
export type NotificationsByStatusQuery = {
  __typename?: 'Query';
  notificationsByStatus?: Array<{
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  }> | null;
};
export type NotificationsByFilterQueryVariables = Exact<{
  filter: NotificationFilterInput;
}>;
export type NotificationsByFilterQuery = {
  __typename?: 'Query';
  notificationsByFilter?: Array<{
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  }> | null;
};
export type GetInvitedStudentsDetailQueryVariables = Exact<{
  params: InvitedStudentsDetailInput;
}>;
export type GetInvitedStudentsDetailQuery = {
  __typename?: 'Query';
  getInvitedStudentsDetail?: Array<{
    __typename?: 'InvitationDetail';
    name?: string | null;
    email?: string | null;
    permission?: StudentPermission | null;
  }> | null;
};
export type GetLatestJoinedOrganizationsWithDetailsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
}>;
export type GetLatestJoinedOrganizationsWithDetailsQuery = {
  __typename?: 'Query';
  getLatestJoinedOrganizationsWithDetails?: Array<{
    __typename?: 'InvitationDetail';
    name?: string | null;
    email?: string | null;
    permission?: StudentPermission | null;
  }> | null;
};
export type CreateNotificationMutationVariables = Exact<{
  data: NotificationInfo;
}>;
export type CreateNotificationMutation = {
  __typename?: 'Mutation';
  notification?: {
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  } | null;
};
export type UpdateNotificationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  data: NotificationInfo;
}>;
export type UpdateNotificationMutation = {
  __typename?: 'Mutation';
  notification?: {
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  } | null;
};
export type ArchiveNotificationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;
export type ArchiveNotificationMutation = {
  __typename?: 'Mutation';
  notification?: {
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  } | null;
};
export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;
export type DeleteNotificationMutation = {
  __typename?: 'Mutation';
  notification?: {
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  } | null;
};
export type ArchiveNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;
export type ArchiveNotificationsMutation = {
  __typename?: 'Mutation';
  notification?: number | null;
};
export type RestoreNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;
export type RestoreNotificationsMutation = {
  __typename?: 'Mutation';
  notification?: number | null;
};
export type OrgFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  slug?: string | null;
  status: OrgStatus;
  permission: PermissionTier;
  primaryColor: PrimaryColor;
  accentColor: AccentColor;
  address?: Array<string> | null;
  resourceOrderKey?: any | null;
  postalCode?: string | null;
  locality?: string | null;
  administrativeArea?: string | null;
  country?: string | null;
  website?: string | null;
  contactPerson?: any | null;
  notes?: string | null;
  types?: Array<Type> | null;
  categories?: Array<Category> | null;
  location?: Location | null;
  size?: Size | null;
  logo?: string | null;
  domains?: Array<string> | null;
  portalText?: string | null;
  helpDesk?: string | null;
  alternateContact?: string | null;
  groups?: Array<string> | null;
  createdAt: any;
  resource?: Array<{
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  }> | null;
};
export type PaginatedOrgListFragment = {
  __typename?: 'PaginatedOrgList';
  previousPage?: number | null;
  nextPage?: number | null;
  total?: number | null;
  totalActive?: number | null;
  totalArchived?: number | null;
  totalPages?: number | null;
  pendingOrgs?: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  }> | null;
  allOrgs?: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  }> | null;
  paginatedOrgList?: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  }> | null;
};
export type OrganizationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type OrganizationQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type OrganizationsQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type OrganizationsQuery = {
  __typename?: 'Query';
  organizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null>;
};
export type AllOrganizationsQueryVariables = Exact<{
  [key: string]: never;
}>;
export type AllOrganizationsQuery = {
  __typename?: 'Query';
  allOrganizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null>;
};
export type AllOrgsForSystemQueryVariables = Exact<{
  searchInput?: InputMaybe<Scalars['String']['input']>;
  paginationData?: InputMaybe<PaginationInput>;
  filters?: InputMaybe<SystemOrgFilter>;
}>;
export type AllOrgsForSystemQuery = {
  __typename?: 'Query';
  allOrgsForSystem?: {
    __typename?: 'PaginatedOrgList';
    previousPage?: number | null;
    nextPage?: number | null;
    total?: number | null;
    totalActive?: number | null;
    totalArchived?: number | null;
    totalPages?: number | null;
    pendingOrgs?: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    }> | null;
    allOrgs?: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    }> | null;
    paginatedOrgList?: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    }> | null;
  } | null;
};
export type OrganizationBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;
export type OrganizationBySlugQuery = {
  __typename?: 'Query';
  organizationBySlug?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type OrganizationsByFilterQueryVariables = Exact<{
  filter: OrganizationFilter;
}>;
export type OrganizationsByFilterQuery = {
  __typename?: 'Query';
  organizationsByFilter: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null>;
};
export type CreateOrganizationMutationVariables = Exact<{
  data: CreateOrganizationInput;
}>;
export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UpdateOrganizationInput;
}>;
export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type UpdateResourceOrderMutationVariables = Exact<{
  id: Scalars['String']['input'];
  orderList: Scalars['JSON']['input'];
}>;
export type UpdateResourceOrderMutation = {
  __typename?: 'Mutation';
  updateResourceOrder?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type UpdateOrganizationGroupsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  newGroups: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type UpdateOrganizationGroupsMutation = {
  __typename?: 'Mutation';
  updateOrganizationGroups?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type ArchiveOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type ArchiveOrganizationMutation = {
  __typename?: 'Mutation';
  archiveOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type ArchiveOrganizationsMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type ArchiveOrganizationsMutation = {
  __typename?: 'Mutation';
  archiveOrganizations: number;
};
export type UnarchiveOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type UnarchiveOrganizationMutation = {
  __typename?: 'Mutation';
  unarchiveOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type UnarchiveOrganizationsMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type UnarchiveOrganizationsMutation = {
  __typename?: 'Mutation';
  unarchiveOrganizations: number;
};
export type ResourceFragment = {
  __typename?: 'Resource';
  id: string;
  orgId?: string | null;
  name: string;
  link: string;
  thumbNail?: string | null;
  fileUrl?: string | null;
  fileType: FileType;
  resourceType: ResourceType;
};
export type ResourceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type ResourceQuery = {
  __typename?: 'Query';
  resource?: {
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  } | null;
};
export type ResourcesByOrgIdQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
}>;
export type ResourcesByOrgIdQuery = {
  __typename?: 'Query';
  resourcesByOrgId?: Array<{
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  }> | null;
};
export type GetPrimaryResourcesQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetPrimaryResourcesQuery = {
  __typename?: 'Query';
  getPrimaryResources?: Array<{
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  }> | null;
};
export type CreateResourceMutationVariables = Exact<{
  name: Scalars['String']['input'];
  data: ResourceInput;
}>;
export type CreateResourceMutation = {
  __typename?: 'Mutation';
  createResource?: {
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  } | null;
};
export type UpdateResourceMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: ResourceInput;
}>;
export type UpdateResourceMutation = {
  __typename?: 'Mutation';
  updateResource?: {
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  } | null;
};
export type DeleteResourceMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type DeleteResourceMutation = {
  __typename?: 'Mutation';
  deleteResource?: {
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  } | null;
};
export type UserLessonAnswerFragment = {
  __typename?: 'UserLessonAnswer';
  id?: string | null;
  progressId?: string | null;
  userId?: string | null;
  questionId?: string | null;
  answerPayload?: any | null;
  timeAnswered?: any | null;
  user?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type StudentsExperienceResultFragment = {
  __typename?: 'StudentsExperienceResult';
  answer?: string | null;
  grandTotal: number;
  total: number;
  under21: number;
  over21: number;
  male: number;
  female: number;
  nonBinary: number;
};
export type MetricsFragment = {
  __typename?: 'Metrics';
  riskFactorsCount: number;
  belowAverageCount: number;
  virtualBarAverageTime: number;
  expressConcernCount: number;
  dashboardData: {
    __typename?: 'DashboardData';
    administratorsInvited: number;
    administratorsRegistered: number;
    averageTimeOnPlatform: number;
    countAllOrganizations: number;
    studentsComplete: number;
    studentsExempt: number;
    studentsInProgress: number;
    studentsRostered: number;
    studentsRegistered: number;
  };
  studentsExperienceData: {
    __typename?: 'StudentsExperienceData';
    experienceQ07: {
      __typename?: 'ExperienceQ07';
      LONG_AGO: {
        __typename?: 'StudentsExperienceResult';
        answer?: string | null;
        grandTotal: number;
        total: number;
        under21: number;
        over21: number;
        male: number;
        female: number;
        nonBinary: number;
      };
      MONTH: {
        __typename?: 'StudentsExperienceResult';
        answer?: string | null;
        grandTotal: number;
        total: number;
        under21: number;
        over21: number;
        male: number;
        female: number;
        nonBinary: number;
      };
      NEVER: {
        __typename?: 'StudentsExperienceResult';
        answer?: string | null;
        grandTotal: number;
        total: number;
        under21: number;
        over21: number;
        male: number;
        female: number;
        nonBinary: number;
      };
      YEAR: {
        __typename?: 'StudentsExperienceResult';
        answer?: string | null;
        grandTotal: number;
        total: number;
        under21: number;
        over21: number;
        male: number;
        female: number;
        nonBinary: number;
      };
    };
    experienceQ11: {
      __typename?: 'StudentsExperienceResult';
      answer?: string | null;
      grandTotal: number;
      total: number;
      under21: number;
      over21: number;
      male: number;
      female: number;
      nonBinary: number;
    };
  };
  questionCountData: {
    __typename?: 'QuestionCountData';
    Q07: number;
    Q13: number;
    Q14: number;
    Q14a: number;
    Q14b: number;
    Q17: number;
    Q18: number;
    Q32: number;
  };
  questionAnswerCountData: {
    __typename?: 'QuestionAnswerCountData';
    Q32: number;
  };
  positiveEffectCount: {
    __typename?: 'PositiveEffectCount';
    ACCOMPLISHED: number;
    FEEL: number;
    FUN: number;
    MEET: number;
    RELAX: number;
    SOCIALIZE: number;
    TASTE: number;
  };
  negativeEffectCount: {
    __typename?: 'NegativeEffectCount';
    BAD_DECISION: number;
    BLACKOUT: number;
    BROWNOUT: number;
    DEPRESSED: number;
    DISAPPOINTED: number;
    DROVE: number;
    EMBARRASSED: number;
    GRADES: number;
    HANGOVER: number;
    HURT: number;
    PASSED_OUT: number;
    POLICE_TROUBLE: number;
    PROPERTY: number;
    SCHOOL_TROUBLE: number;
    SEX: number;
    SOCIAL_MEDIA: number;
    SUICIDE: number;
  };
  virtualBarRevisit: {
    __typename?: 'VirtualBarRevisit';
    revisit: number;
    total: number;
  };
  soberNegativeCount: {
    __typename?: 'SoberNegativeCount';
    PROPERTY: number;
    POLICE_TROUBLE: number;
    HURT: number;
    DISAPPOINTED: number;
    GRADES: number;
    DEPRESSED: number;
    SCHOOL_TROUBLE: number;
    BAD_DECISION: number;
    EMBARRASSED: number;
  };
};
export type PrePostAnswerCountFragment = {
  __typename?: 'PrePostAnswerCount';
  pre?: number | null;
  post?: number | null;
};
export type PrePostMetricsFragment = {
  __typename?: 'PrePostMetrics';
  equippedCount: {
    __typename?: 'PrePostEquippedCount';
    YES: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    NO: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    UNSURE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  riskyCount: {
    __typename?: 'PrePostRiskyCount';
    MORE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    LESS: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    NEITHER: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    UNSURE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  protectiveCount: {
    __typename?: 'PrePostProtectiveCount';
    FOOD: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    SELF_MAKE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    POSESS: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    ALTERNATE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    PACE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    PREDETERMINE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    TRACK: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    BUDDY: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    NO_DRUGS: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    OTHER: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  challengedCount: {
    __typename?: 'PrePostChallengedCount';
    YES: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    NO: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    UNSURE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  enjoyedCount: {
    __typename?: 'PrePostEnjoyedCount';
    YES: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    NO: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    UNSURE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  bystanderCount: {
    __typename?: 'PrePostBystanderCount';
    OFFER: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    CALL: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    RECRUIT: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    WAIT: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    REMOVE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  harmfulCount: {
    __typename?: 'PrePostHarmfulCount';
    F2M3: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    F4M4: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    F4M5: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    F5M6: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  partyCount: {
    __typename?: 'PrePostPartyCount';
    FOOD: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    OPEN_CONTAINERS: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    MANAGE_CROWD: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    PLAN_AHEAD: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  questionCountData: {
    __typename?: 'PrePostQuestionCountData';
    Q01: number;
    Q31: number;
    Q32: number;
    Q33: number;
    Q34: number;
    Q35: number;
    Q36: number;
    Q37: number;
    Q38: number;
  };
};
export type UserLessonAnswerQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  progressId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
}>;
export type UserLessonAnswerQuery = {
  __typename?: 'Query';
  userLessonAnswer?: {
    __typename?: 'UserLessonAnswer';
    id?: string | null;
    progressId?: string | null;
    userId?: string | null;
    questionId?: string | null;
    answerPayload?: any | null;
    timeAnswered?: any | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type UserLessonAnswersByCompoundIdQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  progressId: Scalars['String']['input'];
  questionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type UserLessonAnswersByCompoundIdQuery = {
  __typename?: 'Query';
  userLessonAnswersByCompoundId?: Array<{
    __typename?: 'UserLessonAnswer';
    id?: string | null;
    progressId?: string | null;
    userId?: string | null;
    questionId?: string | null;
    answerPayload?: any | null;
    timeAnswered?: any | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
};
export type MetricsQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;
export type MetricsQuery = {
  __typename?: 'Query';
  metrics?: {
    __typename?: 'Metrics';
    riskFactorsCount: number;
    belowAverageCount: number;
    virtualBarAverageTime: number;
    expressConcernCount: number;
    dashboardData: {
      __typename?: 'DashboardData';
      administratorsInvited: number;
      administratorsRegistered: number;
      averageTimeOnPlatform: number;
      countAllOrganizations: number;
      studentsComplete: number;
      studentsExempt: number;
      studentsInProgress: number;
      studentsRostered: number;
      studentsRegistered: number;
    };
    studentsExperienceData: {
      __typename?: 'StudentsExperienceData';
      experienceQ07: {
        __typename?: 'ExperienceQ07';
        LONG_AGO: {
          __typename?: 'StudentsExperienceResult';
          answer?: string | null;
          grandTotal: number;
          total: number;
          under21: number;
          over21: number;
          male: number;
          female: number;
          nonBinary: number;
        };
        MONTH: {
          __typename?: 'StudentsExperienceResult';
          answer?: string | null;
          grandTotal: number;
          total: number;
          under21: number;
          over21: number;
          male: number;
          female: number;
          nonBinary: number;
        };
        NEVER: {
          __typename?: 'StudentsExperienceResult';
          answer?: string | null;
          grandTotal: number;
          total: number;
          under21: number;
          over21: number;
          male: number;
          female: number;
          nonBinary: number;
        };
        YEAR: {
          __typename?: 'StudentsExperienceResult';
          answer?: string | null;
          grandTotal: number;
          total: number;
          under21: number;
          over21: number;
          male: number;
          female: number;
          nonBinary: number;
        };
      };
      experienceQ11: {
        __typename?: 'StudentsExperienceResult';
        answer?: string | null;
        grandTotal: number;
        total: number;
        under21: number;
        over21: number;
        male: number;
        female: number;
        nonBinary: number;
      };
    };
    questionCountData: {
      __typename?: 'QuestionCountData';
      Q07: number;
      Q13: number;
      Q14: number;
      Q14a: number;
      Q14b: number;
      Q17: number;
      Q18: number;
      Q32: number;
    };
    questionAnswerCountData: {
      __typename?: 'QuestionAnswerCountData';
      Q32: number;
    };
    positiveEffectCount: {
      __typename?: 'PositiveEffectCount';
      ACCOMPLISHED: number;
      FEEL: number;
      FUN: number;
      MEET: number;
      RELAX: number;
      SOCIALIZE: number;
      TASTE: number;
    };
    negativeEffectCount: {
      __typename?: 'NegativeEffectCount';
      BAD_DECISION: number;
      BLACKOUT: number;
      BROWNOUT: number;
      DEPRESSED: number;
      DISAPPOINTED: number;
      DROVE: number;
      EMBARRASSED: number;
      GRADES: number;
      HANGOVER: number;
      HURT: number;
      PASSED_OUT: number;
      POLICE_TROUBLE: number;
      PROPERTY: number;
      SCHOOL_TROUBLE: number;
      SEX: number;
      SOCIAL_MEDIA: number;
      SUICIDE: number;
    };
    virtualBarRevisit: {
      __typename?: 'VirtualBarRevisit';
      revisit: number;
      total: number;
    };
    soberNegativeCount: {
      __typename?: 'SoberNegativeCount';
      PROPERTY: number;
      POLICE_TROUBLE: number;
      HURT: number;
      DISAPPOINTED: number;
      GRADES: number;
      DEPRESSED: number;
      SCHOOL_TROUBLE: number;
      BAD_DECISION: number;
      EMBARRASSED: number;
    };
  } | null;
};
export type PrePostMetricsQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;
export type PrePostMetricsQuery = {
  __typename?: 'Query';
  prePostMetrics?: {
    __typename?: 'PrePostMetrics';
    equippedCount: {
      __typename?: 'PrePostEquippedCount';
      YES: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      NO: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      UNSURE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    riskyCount: {
      __typename?: 'PrePostRiskyCount';
      MORE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      LESS: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      NEITHER: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      UNSURE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    protectiveCount: {
      __typename?: 'PrePostProtectiveCount';
      FOOD: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      SELF_MAKE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      POSESS: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      ALTERNATE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      PACE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      PREDETERMINE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      TRACK: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      BUDDY: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      NO_DRUGS: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      OTHER: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    challengedCount: {
      __typename?: 'PrePostChallengedCount';
      YES: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      NO: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      UNSURE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    enjoyedCount: {
      __typename?: 'PrePostEnjoyedCount';
      YES: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      NO: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      UNSURE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    bystanderCount: {
      __typename?: 'PrePostBystanderCount';
      OFFER: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      CALL: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      RECRUIT: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      WAIT: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      REMOVE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    harmfulCount: {
      __typename?: 'PrePostHarmfulCount';
      F2M3: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      F4M4: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      F4M5: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      F5M6: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    partyCount: {
      __typename?: 'PrePostPartyCount';
      FOOD: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      OPEN_CONTAINERS: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      MANAGE_CROWD: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      PLAN_AHEAD: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    questionCountData: {
      __typename?: 'PrePostQuestionCountData';
      Q01: number;
      Q31: number;
      Q32: number;
      Q33: number;
      Q34: number;
      Q35: number;
      Q36: number;
      Q37: number;
      Q38: number;
    };
  } | null;
};
export type UpdateUserLessonAnswerMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  progressId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
  answerPayload: Scalars['JSON']['input'];
}>;
export type UpdateUserLessonAnswerMutation = {
  __typename?: 'Mutation';
  updateUserLessonAnswer: {
    __typename?: 'UserLessonAnswer';
    id?: string | null;
    progressId?: string | null;
    userId?: string | null;
    questionId?: string | null;
    answerPayload?: any | null;
    timeAnswered?: any | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null;
  };
};
export type UserLessonProgressFragment = {
  __typename?: 'UserLessonProgress';
  id?: string | null;
  sectionsComplete?: Array<string> | null;
  userId?: string | null;
  orgId?: string | null;
  startedAt?: any | null;
  completedAt?: any | null;
};
export type UserLessonProgressQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;
export type UserLessonProgressQuery = {
  __typename?: 'Query';
  userLessonProgress: Array<{
    __typename?: 'UserLessonProgress';
    id?: string | null;
    sectionsComplete?: Array<string> | null;
    userId?: string | null;
    orgId?: string | null;
    startedAt?: any | null;
    completedAt?: any | null;
  } | null>;
};
export type CreateUserLessonProgressMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type CreateUserLessonProgressMutation = {
  __typename?: 'Mutation';
  createUserLessonProgress?: {
    __typename?: 'UserLessonProgress';
    id?: string | null;
    sectionsComplete?: Array<string> | null;
    userId?: string | null;
    orgId?: string | null;
    startedAt?: any | null;
    completedAt?: any | null;
  } | null;
};
export type CompleteSectionForUserLessonProgressMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  sectionId: Scalars['String']['input'];
}>;
export type CompleteSectionForUserLessonProgressMutation = {
  __typename?: 'Mutation';
  completeSectionForUserLessonProgress?: {
    __typename?: 'UserLessonProgress';
    id?: string | null;
    sectionsComplete?: Array<string> | null;
    userId?: string | null;
    orgId?: string | null;
    startedAt?: any | null;
    completedAt?: any | null;
  } | null;
};
export type MarkProgressAsCompleteMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;
export type MarkProgressAsCompleteMutation = {
  __typename?: 'Mutation';
  markProgressAsComplete?: {
    __typename?: 'UserLessonProgress';
    id?: string | null;
    sectionsComplete?: Array<string> | null;
    userId?: string | null;
    orgId?: string | null;
    startedAt?: any | null;
    completedAt?: any | null;
  } | null;
};
export type CreateUserLessonProgressesMutationVariables = Exact<{
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type CreateUserLessonProgressesMutation = {
  __typename?: 'Mutation';
  createUserLessonProgresses?: number | null;
};
export type UserOrganizationFragment = {
  __typename?: 'UserOrganization';
  id: string;
  orgId?: string | null;
  userId?: string | null;
  updatedAt?: any | null;
  createdAt?: any | null;
  status?: UserOrgStatus | null;
  precedence?: Precedence | null;
  isActive?: boolean | null;
  isDeleted?: boolean | null;
  schoolYear?: number | null;
  dueDate?: any | null;
  disciplined?: boolean | null;
  exempt?: boolean | null;
  groups?: Array<string> | null;
  seenList?: Array<string> | null;
  deletedAt?: any | null;
};
export type OrganizationOfStudentFragment = {
  __typename?: 'OrganizationOfStudent';
  id: string;
  name: string;
  isJoined: boolean;
  createdAt: any;
};
export type GetOrganizationsByUserIdQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;
export type GetOrganizationsByUserIdQuery = {
  __typename?: 'Query';
  getOrganizationsByUserId?: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null> | null;
};
export type StudentOrgsJoinedAndInvitedQueryVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;
export type StudentOrgsJoinedAndInvitedQuery = {
  __typename?: 'Query';
  studentOrgsjoinedAndInvited: Array<{
    __typename?: 'OrganizationOfStudent';
    id: string;
    name: string;
    isJoined: boolean;
    createdAt: any;
  } | null>;
};
export type UpdateUserOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UserOrganizationInput;
}>;
export type UpdateUserOrganizationMutation = {
  __typename?: 'Mutation';
  updateUserOrganization?: {
    __typename?: 'UserOrganization';
    id: string;
    orgId?: string | null;
    userId?: string | null;
    updatedAt?: any | null;
    createdAt?: any | null;
    status?: UserOrgStatus | null;
    precedence?: Precedence | null;
    isActive?: boolean | null;
    isDeleted?: boolean | null;
    schoolYear?: number | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    groups?: Array<string> | null;
    seenList?: Array<string> | null;
    deletedAt?: any | null;
  } | null;
};
export type CreateUserOrganizationMutationVariables = Exact<{
  data: UserOrganizationInput;
}>;
export type CreateUserOrganizationMutation = {
  __typename?: 'Mutation';
  userOrganization?: {
    __typename?: 'UserOrganization';
    id: string;
    orgId?: string | null;
    userId?: string | null;
    updatedAt?: any | null;
    createdAt?: any | null;
    status?: UserOrgStatus | null;
    precedence?: Precedence | null;
    isActive?: boolean | null;
    isDeleted?: boolean | null;
    schoolYear?: number | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    groups?: Array<string> | null;
    seenList?: Array<string> | null;
    deletedAt?: any | null;
  } | null;
};
export type DeleteUserOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type DeleteUserOrganizationMutation = {
  __typename?: 'Mutation';
  deleteUserOrganization?: {
    __typename?: 'UserOrganization';
    id: string;
    orgId?: string | null;
    userId?: string | null;
    updatedAt?: any | null;
    createdAt?: any | null;
    status?: UserOrgStatus | null;
    precedence?: Precedence | null;
    isActive?: boolean | null;
    isDeleted?: boolean | null;
    schoolYear?: number | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    groups?: Array<string> | null;
    seenList?: Array<string> | null;
    deletedAt?: any | null;
  } | null;
};
export type DeleteAllOrganizationsOfUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;
export type DeleteAllOrganizationsOfUserMutation = {
  __typename?: 'Mutation';
  deleteAllOrganizationsOfUser?: number | null;
};
export type JoinSecondaryOrganizationMutationVariables = Exact<{
  userEmail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
}>;
export type JoinSecondaryOrganizationMutation = {
  __typename?: 'Mutation';
  joinSecondaryOrganization?: {
    __typename?: 'OrganizationOfStudent';
    id: string;
    name: string;
    isJoined: boolean;
    createdAt: any;
  } | null;
};
export type UserPingFragment = {
  __typename?: 'UserPing';
  userId: string;
  createdAt: any;
  orgId?: string | null;
  pageUrl?: string | null;
  payload?: any | null;
  user?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type UserPingsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;
export type UserPingsQuery = {
  __typename?: 'Query';
  userPings?: Array<{
    __typename?: 'UserPing';
    userId: string;
    createdAt: any;
    orgId?: string | null;
    pageUrl?: string | null;
    payload?: any | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
};
export type CreatePingMutationVariables = Exact<{
  data: UserPingInfo;
}>;
export type CreatePingMutation = {
  __typename?: 'Mutation';
  userPing?: {
    __typename?: 'UserPing';
    userId: string;
    createdAt: any;
    orgId?: string | null;
    pageUrl?: string | null;
    payload?: any | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type UserFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null;
  email: string;
  phone?: string | null;
  role: Role;
  roleElevations?: Array<RoleElevation | null> | null;
  status: UserStatus;
  groups?: Array<string> | null;
  schoolYear?: number | null;
  dob?: any | null;
  dueDate?: any | null;
  editAccess?: boolean | null;
  acquisition: string;
  createdAt?: any | null;
  deletedAt?: any | null;
  exempt?: boolean | null;
  disciplined?: boolean | null;
  sex?: Sex | null;
  gender?: string | null;
  orgId?: string | null;
  settings: {
    __typename?: 'Settings';
    blockEmailNotifications?: boolean | null;
  };
  userLessonProgress?: Array<{
    __typename?: 'UserLessonProgress';
    startedAt?: any | null;
    completedAt?: any | null;
    sectionsComplete?: Array<string> | null;
    orgId?: string | null;
    userId?: string | null;
  } | null> | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
  userOrganization?: Array<{
    __typename?: 'UserOrganization';
    id: string;
    orgId?: string | null;
    userId?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    schoolYear?: number | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    groups?: Array<string> | null;
    deletedAt?: any | null;
    organization?: {
      __typename?: 'Organization';
      name: string;
    } | null;
  } | null> | null;
};
export type InviteFragment = {
  __typename?: 'Invite';
  id?: string | null;
  email: string;
  name?: string | null;
  orgId?: string | null;
  role?: Role | null;
  dob?: any | null;
  groups?: Array<string> | null;
  dueDate?: any | null;
  disciplined?: boolean | null;
  exempt?: boolean | null;
  schoolYear?: number | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type AccountFragment = {
  __typename?: 'Account';
  id: string;
  providerId: string;
  providerAccountId: string;
  createdAt: any;
};
export type PaginatedUserListFragment = {
  __typename?: 'PaginatedUserList';
  previousPage?: number | null;
  nextPage?: number | null;
  total?: number | null;
  totalPages?: number | null;
  allUsers?: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null> | null;
  paginatedUserList?: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null> | null;
};
export type StudentForExcelFragment = {
  __typename?: 'StudentForExcel';
  name?: string | null;
  email: string;
  phone?: string | null;
  courseProgress?: string | null;
  role?: Role | null;
  roleElevations?: string | null;
  status?: UserStatus | null;
  groups?: string | null;
  schoolYear?: string | null;
  dueDate?: string | null;
  settings?: string | null;
  userLessonProgress?: string | null;
  createdAt?: string | null;
  deletedAt?: string | null;
  exempt?: string | null;
  disciplined?: string | null;
  sex?: Sex | null;
  gender?: string | null;
  acquisitionChannel?: string | null;
  userSessionStartsEnds?: string | null;
  organization?: string | null;
  organizationType?: string | null;
  organizationCategory?: string | null;
  organizationLocation?: string | null;
  organizationRegion?: string | null;
  organizationState?: string | null;
  organizationEnrollment?: string | null;
  engagedCourseTime?: string | null;
  passiveCourseTime?: string | null;
};
export type SessionStudentFragment = {
  __typename?: 'SessionStudent';
  start?: any | null;
  end?: any | null;
};
export type UserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type UsersQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type UsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null>;
};
export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;
export type UserByEmailQuery = {
  __typename?: 'Query';
  userByEmail?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type InvitedByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;
export type InvitedByEmailQuery = {
  __typename?: 'Query';
  invitedByEmail?: Array<{
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null> | null;
};
export type UserInvitesQueryVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type UserInvitesQuery = {
  __typename?: 'Query';
  userInvites: Array<{
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null>;
};
export type AllStudentsQueryVariables = Exact<{
  [key: string]: never;
}>;
export type AllStudentsQuery = {
  __typename?: 'Query';
  allStudents: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null>;
};
export type AllOrgOwnersQueryVariables = Exact<{
  [key: string]: never;
}>;
export type AllOrgOwnersQuery = {
  __typename?: 'Query';
  allOrgOwners: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null>;
};
export type AllOrgManagersQueryVariables = Exact<{
  [key: string]: never;
}>;
export type AllOrgManagersQuery = {
  __typename?: 'Query';
  allOrgManagers: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null>;
};
export type RegisteredAndInvitedStudentsByOrganizationQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
  paginationData?: InputMaybe<PaginationInput>;
  groups?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;
export type RegisteredAndInvitedStudentsByOrganizationQuery = {
  __typename?: 'Query';
  registeredAndInvitedStudentsByOrganization: {
    __typename?: 'PaginatedUserList';
    previousPage?: number | null;
    nextPage?: number | null;
    total?: number | null;
    totalPages?: number | null;
    allUsers?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
    paginatedUserList?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};
export type RegisteredAndInvitedAdminsByOrganizationQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
  paginationData?: InputMaybe<PaginationInput>;
}>;
export type RegisteredAndInvitedAdminsByOrganizationQuery = {
  __typename?: 'Query';
  registeredAndInvitedAdminsByOrganization: {
    __typename?: 'PaginatedUserList';
    previousPage?: number | null;
    nextPage?: number | null;
    total?: number | null;
    totalPages?: number | null;
    allUsers?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
    paginatedUserList?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};
export type AllRegisteredAndInvitedOrgAdminsQueryVariables = Exact<{
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
  paginationData?: InputMaybe<PaginationInput>;
  filters?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;
export type AllRegisteredAndInvitedOrgAdminsQuery = {
  __typename?: 'Query';
  allRegisteredAndInvitedOrgAdmins: {
    __typename?: 'PaginatedUserList';
    previousPage?: number | null;
    nextPage?: number | null;
    total?: number | null;
    totalPages?: number | null;
    allUsers?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
    paginatedUserList?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};
export type AllRegisteredAndInvitedSystemAdminsQueryVariables = Exact<{
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
  paginationData?: InputMaybe<PaginationInput>;
}>;
export type AllRegisteredAndInvitedSystemAdminsQuery = {
  __typename?: 'Query';
  allRegisteredAndInvitedSystemAdmins: {
    __typename?: 'PaginatedUserList';
    previousPage?: number | null;
    nextPage?: number | null;
    total?: number | null;
    totalPages?: number | null;
    allUsers?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
    paginatedUserList?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};
export type RegisteredAndInvitedUsersQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type RegisteredAndInvitedUsersQuery = {
  __typename?: 'Query';
  registeredAndInvitedUsers: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null>;
};
export type FilteredStudentsForExcelQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;
export type FilteredStudentsForExcelQuery = {
  __typename?: 'Query';
  filteredStudentsForExcel?: Array<{
    __typename?: 'StudentForExcel';
    name?: string | null;
    email: string;
    phone?: string | null;
    courseProgress?: string | null;
    role?: Role | null;
    roleElevations?: string | null;
    status?: UserStatus | null;
    groups?: string | null;
    schoolYear?: string | null;
    dueDate?: string | null;
    settings?: string | null;
    userLessonProgress?: string | null;
    createdAt?: string | null;
    deletedAt?: string | null;
    exempt?: string | null;
    disciplined?: string | null;
    sex?: Sex | null;
    gender?: string | null;
    acquisitionChannel?: string | null;
    userSessionStartsEnds?: string | null;
    organization?: string | null;
    organizationType?: string | null;
    organizationCategory?: string | null;
    organizationLocation?: string | null;
    organizationRegion?: string | null;
    organizationState?: string | null;
    organizationEnrollment?: string | null;
    engagedCourseTime?: string | null;
    passiveCourseTime?: string | null;
  } | null> | null;
};
export type GetStudentMyGroupFilterQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
}>;
export type GetStudentMyGroupFilterQuery = {
  __typename?: 'Query';
  getStudentMyGroupFilter?: Array<string> | null;
};
export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;
export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  data: UpdateUserInput;
}>;
export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type UpdateUsersMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  groupPush?: InputMaybe<Scalars['Boolean']['input']>;
  data: UpdateUserInput;
}>;
export type UpdateUsersMutation = {
  __typename?: 'Mutation';
  updateUsers?: number | null;
};
export type UpdateInviteMutationVariables = Exact<{
  email: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  data: UpdateInviteInput;
}>;
export type UpdateInviteMutation = {
  __typename?: 'Mutation';
  updateInvite?: {
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null;
};
export type UpdateInvitesMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  groupPush?: InputMaybe<Scalars['Boolean']['input']>;
  data: UpdateInviteInput;
}>;
export type UpdateInvitesMutation = {
  __typename?: 'Mutation';
  updateInvites?: number | null;
};
export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type DeleteUsersMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type DeleteUsersMutation = {
  __typename?: 'Mutation';
  deleteUsers?: number | null;
};
export type RestoreUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type RestoreUserMutation = {
  __typename?: 'Mutation';
  restoreUser?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type RestoreUsersMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type RestoreUsersMutation = {
  __typename?: 'Mutation';
  restoreUsers?: number | null;
};
export type CreateInviteMutationVariables = Exact<{
  user: InviteInput;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
}>;
export type CreateInviteMutation = {
  __typename?: 'Mutation';
  createInvite?: {
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null;
};
export type CreateManyInvitesMutationVariables = Exact<{
  users: Array<InviteInput> | InviteInput;
}>;
export type CreateManyInvitesMutation = {
  __typename?: 'Mutation';
  createManyInvites?: Array<{
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null> | null;
};
export type DeleteInviteMutationVariables = Exact<{
  email: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type DeleteInviteMutation = {
  __typename?: 'Mutation';
  deleteInvite?: {
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null;
};
export type DeleteInvitesMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type DeleteInvitesMutation = {
  __typename?: 'Mutation';
  deleteInvites?: number | null;
};
export type VerificationRequestFragment = {
  __typename?: 'VerificationRequest';
  id: number;
  identifier: string;
  token?: string | null;
  expires?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
};
export type GetLatestVerificationRequestQueryVariables = Exact<{
  identifier: Scalars['String']['input'];
}>;
export type GetLatestVerificationRequestQuery = {
  __typename?: 'Query';
  getLatestVerificationRequest?: {
    __typename?: 'VerificationRequest';
    id: number;
    identifier: string;
    token?: string | null;
    expires?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};
export type CreateVerificationRequestMutationVariables = Exact<{
  identifier: Scalars['String']['input'];
  token: Scalars['String']['input'];
  expires: Scalars['DateTime']['input'];
}>;
export type CreateVerificationRequestMutation = {
  __typename?: 'Mutation';
  createVerificationRequest?: {
    __typename?: 'VerificationRequest';
    id: number;
    identifier: string;
    token?: string | null;
    expires?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};
export const NotificationFragmentDoc = `
    fragment notification on Notification {
  id
  headline
  body
  isDraft
  audience
  seenList
  orgId
  userId
  deliveryType
  dateToSend
  expirationDate
  createdAt
  deletedAt
}
    `;
export const InvitationDetailFragmentDoc = `
    fragment invitationDetail on InvitationDetail {
  name
  email
  permission
}
    `;
export const ResourceFragmentDoc = `
    fragment resource on Resource {
  id
  orgId
  name
  link
  thumbNail
  fileUrl
  fileType
  resourceType
}
    `;
export const OrgFragmentDoc = `
    fragment org on Organization {
  id
  name
  slug
  status
  permission
  primaryColor
  accentColor
  address
  resourceOrderKey
  postalCode
  locality
  administrativeArea
  country
  website
  contactPerson
  notes
  types
  categories
  location
  size
  logo
  domains
  portalText
  helpDesk
  alternateContact
  groups
  createdAt
  resource {
    ...resource
  }
}
    ${ResourceFragmentDoc}`;
export const PaginatedOrgListFragmentDoc = `
    fragment paginatedOrgList on PaginatedOrgList {
  previousPage
  nextPage
  total
  totalActive
  totalArchived
  totalPages
  pendingOrgs {
    ...org
  }
  allOrgs {
    ...org
  }
  paginatedOrgList {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const UserFragmentDoc = `
    fragment user on User {
  id
  name
  email
  phone
  role
  roleElevations
  status
  groups
  schoolYear
  dob
  dueDate
  editAccess
  acquisition
  settings {
    blockEmailNotifications
  }
  userLessonProgress {
    startedAt
    completedAt
    sectionsComplete
    orgId
    userId
  }
  createdAt
  deletedAt
  exempt
  disciplined
  sex
  gender
  orgId
  organization {
    ...org
  }
  userOrganization {
    id
    orgId
    userId
    createdAt
    updatedAt
    schoolYear
    dueDate
    disciplined
    exempt
    groups
    deletedAt
    organization {
      name
    }
    deletedAt
  }
}
    ${OrgFragmentDoc}`;
export const UserLessonAnswerFragmentDoc = `
    fragment userLessonAnswer on UserLessonAnswer {
  id
  progressId
  userId
  questionId
  answerPayload
  timeAnswered
  user {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const StudentsExperienceResultFragmentDoc = `
    fragment studentsExperienceResult on StudentsExperienceResult {
  answer
  grandTotal
  total
  under21
  over21
  male
  female
  nonBinary
}
    `;
export const MetricsFragmentDoc = `
    fragment metrics on Metrics {
  dashboardData {
    administratorsInvited
    administratorsRegistered
    averageTimeOnPlatform
    countAllOrganizations
    studentsComplete
    studentsExempt
    studentsInProgress
    studentsRostered
    studentsRegistered
  }
  studentsExperienceData {
    experienceQ07 {
      LONG_AGO {
        ...studentsExperienceResult
      }
      MONTH {
        ...studentsExperienceResult
      }
      NEVER {
        ...studentsExperienceResult
      }
      YEAR {
        ...studentsExperienceResult
      }
    }
    experienceQ11 {
      ...studentsExperienceResult
    }
  }
  questionCountData {
    Q07
    Q13
    Q14
    Q14a
    Q14b
    Q17
    Q18
    Q32
  }
  questionAnswerCountData {
    Q32
  }
  positiveEffectCount {
    ACCOMPLISHED
    FEEL
    FUN
    MEET
    RELAX
    SOCIALIZE
    TASTE
  }
  negativeEffectCount {
    BAD_DECISION
    BLACKOUT
    BROWNOUT
    DEPRESSED
    DISAPPOINTED
    DROVE
    EMBARRASSED
    GRADES
    HANGOVER
    HURT
    PASSED_OUT
    POLICE_TROUBLE
    PROPERTY
    SCHOOL_TROUBLE
    SEX
    SOCIAL_MEDIA
    SUICIDE
  }
  riskFactorsCount
  belowAverageCount
  virtualBarAverageTime
  virtualBarRevisit {
    revisit
    total
  }
  soberNegativeCount {
    PROPERTY
    POLICE_TROUBLE
    HURT
    DISAPPOINTED
    GRADES
    DEPRESSED
    SCHOOL_TROUBLE
    BAD_DECISION
    EMBARRASSED
  }
  expressConcernCount
}
    ${StudentsExperienceResultFragmentDoc}`;
export const PrePostAnswerCountFragmentDoc = `
    fragment prePostAnswerCount on PrePostAnswerCount {
  pre
  post
}
    `;
export const PrePostMetricsFragmentDoc = `
    fragment prePostMetrics on PrePostMetrics {
  equippedCount {
    YES {
      ...prePostAnswerCount
    }
    NO {
      ...prePostAnswerCount
    }
    UNSURE {
      ...prePostAnswerCount
    }
  }
  riskyCount {
    MORE {
      ...prePostAnswerCount
    }
    LESS {
      ...prePostAnswerCount
    }
    NEITHER {
      ...prePostAnswerCount
    }
    UNSURE {
      ...prePostAnswerCount
    }
  }
  protectiveCount {
    FOOD {
      ...prePostAnswerCount
    }
    SELF_MAKE {
      ...prePostAnswerCount
    }
    POSESS {
      ...prePostAnswerCount
    }
    ALTERNATE {
      ...prePostAnswerCount
    }
    PACE {
      ...prePostAnswerCount
    }
    PREDETERMINE {
      ...prePostAnswerCount
    }
    TRACK {
      ...prePostAnswerCount
    }
    BUDDY {
      ...prePostAnswerCount
    }
    NO_DRUGS {
      ...prePostAnswerCount
    }
    OTHER {
      ...prePostAnswerCount
    }
  }
  challengedCount {
    YES {
      ...prePostAnswerCount
    }
    NO {
      ...prePostAnswerCount
    }
    UNSURE {
      ...prePostAnswerCount
    }
  }
  enjoyedCount {
    YES {
      ...prePostAnswerCount
    }
    NO {
      ...prePostAnswerCount
    }
    UNSURE {
      ...prePostAnswerCount
    }
  }
  bystanderCount {
    OFFER {
      ...prePostAnswerCount
    }
    CALL {
      ...prePostAnswerCount
    }
    RECRUIT {
      ...prePostAnswerCount
    }
    WAIT {
      ...prePostAnswerCount
    }
    REMOVE {
      ...prePostAnswerCount
    }
  }
  harmfulCount {
    F2M3 {
      ...prePostAnswerCount
    }
    F4M4 {
      ...prePostAnswerCount
    }
    F4M5 {
      ...prePostAnswerCount
    }
    F5M6 {
      ...prePostAnswerCount
    }
  }
  partyCount {
    FOOD {
      ...prePostAnswerCount
    }
    OPEN_CONTAINERS {
      ...prePostAnswerCount
    }
    MANAGE_CROWD {
      ...prePostAnswerCount
    }
    PLAN_AHEAD {
      ...prePostAnswerCount
    }
  }
  questionCountData {
    Q01
    Q31
    Q32
    Q33
    Q34
    Q35
    Q36
    Q37
    Q38
  }
}
    ${PrePostAnswerCountFragmentDoc}`;
export const UserLessonProgressFragmentDoc = `
    fragment userLessonProgress on UserLessonProgress {
  id
  sectionsComplete
  userId
  orgId
  startedAt
  completedAt
}
    `;
export const UserOrganizationFragmentDoc = `
    fragment userOrganization on UserOrganization {
  id
  orgId
  userId
  updatedAt
  createdAt
  status
  precedence
  isActive
  isDeleted
  schoolYear
  dueDate
  disciplined
  exempt
  groups
  seenList
  deletedAt
}
    `;
export const OrganizationOfStudentFragmentDoc = `
    fragment organizationOfStudent on OrganizationOfStudent {
  id
  name
  isJoined
  createdAt
}
    `;
export const UserPingFragmentDoc = `
    fragment userPing on UserPing {
  userId
  createdAt
  orgId
  pageUrl
  payload
  user {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const InviteFragmentDoc = `
    fragment invite on Invite {
  id
  email
  name
  orgId
  organization {
    ...org
  }
  role
  dob
  groups
  dueDate
  disciplined
  exempt
  schoolYear
}
    ${OrgFragmentDoc}`;
export const AccountFragmentDoc = `
    fragment account on Account {
  id
  providerId
  providerAccountId
  createdAt
}
    `;
export const PaginatedUserListFragmentDoc = `
    fragment paginatedUserList on PaginatedUserList {
  previousPage
  nextPage
  total
  totalPages
  allUsers {
    ...user
  }
  paginatedUserList {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const StudentForExcelFragmentDoc = `
    fragment studentForExcel on StudentForExcel {
  name
  email
  phone
  courseProgress
  role
  roleElevations
  status
  groups
  schoolYear
  dueDate
  settings
  userLessonProgress
  createdAt
  deletedAt
  exempt
  disciplined
  sex
  gender
  acquisitionChannel
  userSessionStartsEnds
  organization
  organizationType
  organizationCategory
  organizationLocation
  organizationRegion
  organizationState
  organizationEnrollment
  engagedCourseTime
  passiveCourseTime
}
    `;
export const SessionStudentFragmentDoc = `
    fragment sessionStudent on SessionStudent {
  start
  end
}
    `;
export const VerificationRequestFragmentDoc = `
    fragment verificationRequest on VerificationRequest {
  id
  identifier
  token
  expires
  createdAt
  updatedAt
}
    `;
export const NotificationDocument = `
    query notification($id: Int!) {
  notification(id: $id) {
    ...notification
  }
}
    ${NotificationFragmentDoc}`;
export const useNotificationQuery = <TData = NotificationQuery, TError = unknown>(variables: NotificationQueryVariables, options?: Omit<UseQueryOptions<NotificationQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<NotificationQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<NotificationQuery, TError, TData>({
    queryKey: ['notification', variables],
    queryFn: fetcher<NotificationQuery, NotificationQueryVariables>(NotificationDocument, variables),
    ...options
  });
};
export const NotificationsByStatusDocument = `
    query notificationsByStatus($isDraft: Boolean!, $orgId: String!) {
  notificationsByStatus(isDraft: $isDraft, orgId: $orgId) {
    ...notification
  }
}
    ${NotificationFragmentDoc}`;
export const useNotificationsByStatusQuery = <TData = NotificationsByStatusQuery, TError = unknown>(variables: NotificationsByStatusQueryVariables, options?: Omit<UseQueryOptions<NotificationsByStatusQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<NotificationsByStatusQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<NotificationsByStatusQuery, TError, TData>({
    queryKey: ['notificationsByStatus', variables],
    queryFn: fetcher<NotificationsByStatusQuery, NotificationsByStatusQueryVariables>(NotificationsByStatusDocument, variables),
    ...options
  });
};
export const NotificationsByFilterDocument = `
    query notificationsByFilter($filter: NotificationFilterInput!) {
  notificationsByFilter(filter: $filter) {
    ...notification
  }
}
    ${NotificationFragmentDoc}`;
export const useNotificationsByFilterQuery = <TData = NotificationsByFilterQuery, TError = unknown>(variables: NotificationsByFilterQueryVariables, options?: Omit<UseQueryOptions<NotificationsByFilterQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<NotificationsByFilterQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<NotificationsByFilterQuery, TError, TData>({
    queryKey: ['notificationsByFilter', variables],
    queryFn: fetcher<NotificationsByFilterQuery, NotificationsByFilterQueryVariables>(NotificationsByFilterDocument, variables),
    ...options
  });
};
export const GetInvitedStudentsDetailDocument = `
    query getInvitedStudentsDetail($params: InvitedStudentsDetailInput!) {
  getInvitedStudentsDetail(params: $params) {
    ...invitationDetail
  }
}
    ${InvitationDetailFragmentDoc}`;
export const useGetInvitedStudentsDetailQuery = <TData = GetInvitedStudentsDetailQuery, TError = unknown>(variables: GetInvitedStudentsDetailQueryVariables, options?: Omit<UseQueryOptions<GetInvitedStudentsDetailQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<GetInvitedStudentsDetailQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<GetInvitedStudentsDetailQuery, TError, TData>({
    queryKey: ['getInvitedStudentsDetail', variables],
    queryFn: fetcher<GetInvitedStudentsDetailQuery, GetInvitedStudentsDetailQueryVariables>(GetInvitedStudentsDetailDocument, variables),
    ...options
  });
};
export const GetLatestJoinedOrganizationsWithDetailsDocument = `
    query getLatestJoinedOrganizationsWithDetails($userId: String!, $orgId: String!) {
  getLatestJoinedOrganizationsWithDetails(userId: $userId, orgId: $orgId) {
    ...invitationDetail
  }
}
    ${InvitationDetailFragmentDoc}`;
export const useGetLatestJoinedOrganizationsWithDetailsQuery = <TData = GetLatestJoinedOrganizationsWithDetailsQuery, TError = unknown>(variables: GetLatestJoinedOrganizationsWithDetailsQueryVariables, options?: Omit<UseQueryOptions<GetLatestJoinedOrganizationsWithDetailsQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<GetLatestJoinedOrganizationsWithDetailsQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<GetLatestJoinedOrganizationsWithDetailsQuery, TError, TData>({
    queryKey: ['getLatestJoinedOrganizationsWithDetails', variables],
    queryFn: fetcher<GetLatestJoinedOrganizationsWithDetailsQuery, GetLatestJoinedOrganizationsWithDetailsQueryVariables>(GetLatestJoinedOrganizationsWithDetailsDocument, variables),
    ...options
  });
};
export const CreateNotificationDocument = `
    mutation createNotification($data: NotificationInfo!) {
  notification: createNotification(data: $data) {
    ...notification
  }
}
    ${NotificationFragmentDoc}`;
export const useCreateNotificationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreateNotificationMutation, TError, CreateNotificationMutationVariables, TContext>) => {
  return useMutation<CreateNotificationMutation, TError, CreateNotificationMutationVariables, TContext>({
    mutationKey: ['createNotification'],
    mutationFn: (variables?: CreateNotificationMutationVariables) => fetcher<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, variables)(),
    ...options
  });
};
export const UpdateNotificationDocument = `
    mutation updateNotification($id: Int!, $data: NotificationInfo!) {
  notification: updateNotification(id: $id, data: $data) {
    ...notification
  }
}
    ${NotificationFragmentDoc}`;
export const useUpdateNotificationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateNotificationMutation, TError, UpdateNotificationMutationVariables, TContext>) => {
  return useMutation<UpdateNotificationMutation, TError, UpdateNotificationMutationVariables, TContext>({
    mutationKey: ['updateNotification'],
    mutationFn: (variables?: UpdateNotificationMutationVariables) => fetcher<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, variables)(),
    ...options
  });
};
export const ArchiveNotificationDocument = `
    mutation archiveNotification($id: Int!) {
  notification: archiveNotification(id: $id) {
    ...notification
  }
}
    ${NotificationFragmentDoc}`;
export const useArchiveNotificationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<ArchiveNotificationMutation, TError, ArchiveNotificationMutationVariables, TContext>) => {
  return useMutation<ArchiveNotificationMutation, TError, ArchiveNotificationMutationVariables, TContext>({
    mutationKey: ['archiveNotification'],
    mutationFn: (variables?: ArchiveNotificationMutationVariables) => fetcher<ArchiveNotificationMutation, ArchiveNotificationMutationVariables>(ArchiveNotificationDocument, variables)(),
    ...options
  });
};
export const DeleteNotificationDocument = `
    mutation deleteNotification($id: Int!) {
  notification: deleteNotification(id: $id) {
    ...notification
  }
}
    ${NotificationFragmentDoc}`;
export const useDeleteNotificationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<DeleteNotificationMutation, TError, DeleteNotificationMutationVariables, TContext>) => {
  return useMutation<DeleteNotificationMutation, TError, DeleteNotificationMutationVariables, TContext>({
    mutationKey: ['deleteNotification'],
    mutationFn: (variables?: DeleteNotificationMutationVariables) => fetcher<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, variables)(),
    ...options
  });
};
export const ArchiveNotificationsDocument = `
    mutation archiveNotifications($ids: [Int!]!) {
  notification: archiveNotifications(ids: $ids)
}
    `;
export const useArchiveNotificationsMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<ArchiveNotificationsMutation, TError, ArchiveNotificationsMutationVariables, TContext>) => {
  return useMutation<ArchiveNotificationsMutation, TError, ArchiveNotificationsMutationVariables, TContext>({
    mutationKey: ['archiveNotifications'],
    mutationFn: (variables?: ArchiveNotificationsMutationVariables) => fetcher<ArchiveNotificationsMutation, ArchiveNotificationsMutationVariables>(ArchiveNotificationsDocument, variables)(),
    ...options
  });
};
export const RestoreNotificationsDocument = `
    mutation restoreNotifications($ids: [Int!]!) {
  notification: restoreNotifications(ids: $ids)
}
    `;
export const useRestoreNotificationsMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<RestoreNotificationsMutation, TError, RestoreNotificationsMutationVariables, TContext>) => {
  return useMutation<RestoreNotificationsMutation, TError, RestoreNotificationsMutationVariables, TContext>({
    mutationKey: ['restoreNotifications'],
    mutationFn: (variables?: RestoreNotificationsMutationVariables) => fetcher<RestoreNotificationsMutation, RestoreNotificationsMutationVariables>(RestoreNotificationsDocument, variables)(),
    ...options
  });
};
export const OrganizationDocument = `
    query organization($id: String!) {
  organization(id: $id) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useOrganizationQuery = <TData = OrganizationQuery, TError = unknown>(variables: OrganizationQueryVariables, options?: Omit<UseQueryOptions<OrganizationQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<OrganizationQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<OrganizationQuery, TError, TData>({
    queryKey: ['organization', variables],
    queryFn: fetcher<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, variables),
    ...options
  });
};
export const OrganizationsDocument = `
    query organizations($ids: [String!]!) {
  organizations(ids: $ids) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useOrganizationsQuery = <TData = OrganizationsQuery, TError = unknown>(variables: OrganizationsQueryVariables, options?: Omit<UseQueryOptions<OrganizationsQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<OrganizationsQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<OrganizationsQuery, TError, TData>({
    queryKey: ['organizations', variables],
    queryFn: fetcher<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, variables),
    ...options
  });
};
export const AllOrganizationsDocument = `
    query allOrganizations {
  allOrganizations {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useAllOrganizationsQuery = <TData = AllOrganizationsQuery, TError = unknown>(variables?: AllOrganizationsQueryVariables, options?: Omit<UseQueryOptions<AllOrganizationsQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<AllOrganizationsQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<AllOrganizationsQuery, TError, TData>({
    queryKey: variables === undefined ? ['allOrganizations'] : ['allOrganizations', variables],
    queryFn: fetcher<AllOrganizationsQuery, AllOrganizationsQueryVariables>(AllOrganizationsDocument, variables),
    ...options
  });
};
export const AllOrgsForSystemDocument = `
    query allOrgsForSystem($searchInput: String, $paginationData: PaginationInput, $filters: SystemOrgFilter) {
  allOrgsForSystem(
    searchInput: $searchInput
    paginationData: $paginationData
    filters: $filters
  ) {
    ...paginatedOrgList
  }
}
    ${PaginatedOrgListFragmentDoc}`;
export const useAllOrgsForSystemQuery = <TData = AllOrgsForSystemQuery, TError = unknown>(variables?: AllOrgsForSystemQueryVariables, options?: Omit<UseQueryOptions<AllOrgsForSystemQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<AllOrgsForSystemQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<AllOrgsForSystemQuery, TError, TData>({
    queryKey: variables === undefined ? ['allOrgsForSystem'] : ['allOrgsForSystem', variables],
    queryFn: fetcher<AllOrgsForSystemQuery, AllOrgsForSystemQueryVariables>(AllOrgsForSystemDocument, variables),
    ...options
  });
};
export const OrganizationBySlugDocument = `
    query organizationBySlug($slug: String!) {
  organizationBySlug(slug: $slug) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useOrganizationBySlugQuery = <TData = OrganizationBySlugQuery, TError = unknown>(variables: OrganizationBySlugQueryVariables, options?: Omit<UseQueryOptions<OrganizationBySlugQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<OrganizationBySlugQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<OrganizationBySlugQuery, TError, TData>({
    queryKey: ['organizationBySlug', variables],
    queryFn: fetcher<OrganizationBySlugQuery, OrganizationBySlugQueryVariables>(OrganizationBySlugDocument, variables),
    ...options
  });
};
export const OrganizationsByFilterDocument = `
    query organizationsByFilter($filter: OrganizationFilter!) {
  organizationsByFilter(filter: $filter) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useOrganizationsByFilterQuery = <TData = OrganizationsByFilterQuery, TError = unknown>(variables: OrganizationsByFilterQueryVariables, options?: Omit<UseQueryOptions<OrganizationsByFilterQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<OrganizationsByFilterQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<OrganizationsByFilterQuery, TError, TData>({
    queryKey: ['organizationsByFilter', variables],
    queryFn: fetcher<OrganizationsByFilterQuery, OrganizationsByFilterQueryVariables>(OrganizationsByFilterDocument, variables),
    ...options
  });
};
export const CreateOrganizationDocument = `
    mutation createOrganization($data: CreateOrganizationInput!) {
  createOrganization(data: $data) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useCreateOrganizationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreateOrganizationMutation, TError, CreateOrganizationMutationVariables, TContext>) => {
  return useMutation<CreateOrganizationMutation, TError, CreateOrganizationMutationVariables, TContext>({
    mutationKey: ['createOrganization'],
    mutationFn: (variables?: CreateOrganizationMutationVariables) => fetcher<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, variables)(),
    ...options
  });
};
export const UpdateOrganizationDocument = `
    mutation updateOrganization($id: String!, $data: UpdateOrganizationInput!) {
  updateOrganization(id: $id, data: $data) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useUpdateOrganizationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateOrganizationMutation, TError, UpdateOrganizationMutationVariables, TContext>) => {
  return useMutation<UpdateOrganizationMutation, TError, UpdateOrganizationMutationVariables, TContext>({
    mutationKey: ['updateOrganization'],
    mutationFn: (variables?: UpdateOrganizationMutationVariables) => fetcher<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, variables)(),
    ...options
  });
};
export const UpdateResourceOrderDocument = `
    mutation updateResourceOrder($id: String!, $orderList: JSON!) {
  updateResourceOrder(id: $id, orderList: $orderList) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useUpdateResourceOrderMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateResourceOrderMutation, TError, UpdateResourceOrderMutationVariables, TContext>) => {
  return useMutation<UpdateResourceOrderMutation, TError, UpdateResourceOrderMutationVariables, TContext>({
    mutationKey: ['updateResourceOrder'],
    mutationFn: (variables?: UpdateResourceOrderMutationVariables) => fetcher<UpdateResourceOrderMutation, UpdateResourceOrderMutationVariables>(UpdateResourceOrderDocument, variables)(),
    ...options
  });
};
export const UpdateOrganizationGroupsDocument = `
    mutation updateOrganizationGroups($id: String!, $newGroups: [String!]!) {
  updateOrganizationGroups(id: $id, newGroups: $newGroups) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useUpdateOrganizationGroupsMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateOrganizationGroupsMutation, TError, UpdateOrganizationGroupsMutationVariables, TContext>) => {
  return useMutation<UpdateOrganizationGroupsMutation, TError, UpdateOrganizationGroupsMutationVariables, TContext>({
    mutationKey: ['updateOrganizationGroups'],
    mutationFn: (variables?: UpdateOrganizationGroupsMutationVariables) => fetcher<UpdateOrganizationGroupsMutation, UpdateOrganizationGroupsMutationVariables>(UpdateOrganizationGroupsDocument, variables)(),
    ...options
  });
};
export const ArchiveOrganizationDocument = `
    mutation archiveOrganization($id: String!) {
  archiveOrganization(id: $id) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useArchiveOrganizationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<ArchiveOrganizationMutation, TError, ArchiveOrganizationMutationVariables, TContext>) => {
  return useMutation<ArchiveOrganizationMutation, TError, ArchiveOrganizationMutationVariables, TContext>({
    mutationKey: ['archiveOrganization'],
    mutationFn: (variables?: ArchiveOrganizationMutationVariables) => fetcher<ArchiveOrganizationMutation, ArchiveOrganizationMutationVariables>(ArchiveOrganizationDocument, variables)(),
    ...options
  });
};
export const ArchiveOrganizationsDocument = `
    mutation archiveOrganizations($ids: [String!]!) {
  archiveOrganizations(ids: $ids)
}
    `;
export const useArchiveOrganizationsMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<ArchiveOrganizationsMutation, TError, ArchiveOrganizationsMutationVariables, TContext>) => {
  return useMutation<ArchiveOrganizationsMutation, TError, ArchiveOrganizationsMutationVariables, TContext>({
    mutationKey: ['archiveOrganizations'],
    mutationFn: (variables?: ArchiveOrganizationsMutationVariables) => fetcher<ArchiveOrganizationsMutation, ArchiveOrganizationsMutationVariables>(ArchiveOrganizationsDocument, variables)(),
    ...options
  });
};
export const UnarchiveOrganizationDocument = `
    mutation unarchiveOrganization($id: String!) {
  unarchiveOrganization(id: $id) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useUnarchiveOrganizationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UnarchiveOrganizationMutation, TError, UnarchiveOrganizationMutationVariables, TContext>) => {
  return useMutation<UnarchiveOrganizationMutation, TError, UnarchiveOrganizationMutationVariables, TContext>({
    mutationKey: ['unarchiveOrganization'],
    mutationFn: (variables?: UnarchiveOrganizationMutationVariables) => fetcher<UnarchiveOrganizationMutation, UnarchiveOrganizationMutationVariables>(UnarchiveOrganizationDocument, variables)(),
    ...options
  });
};
export const UnarchiveOrganizationsDocument = `
    mutation unarchiveOrganizations($ids: [String!]!) {
  unarchiveOrganizations(ids: $ids)
}
    `;
export const useUnarchiveOrganizationsMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UnarchiveOrganizationsMutation, TError, UnarchiveOrganizationsMutationVariables, TContext>) => {
  return useMutation<UnarchiveOrganizationsMutation, TError, UnarchiveOrganizationsMutationVariables, TContext>({
    mutationKey: ['unarchiveOrganizations'],
    mutationFn: (variables?: UnarchiveOrganizationsMutationVariables) => fetcher<UnarchiveOrganizationsMutation, UnarchiveOrganizationsMutationVariables>(UnarchiveOrganizationsDocument, variables)(),
    ...options
  });
};
export const ResourceDocument = `
    query resource($id: String!) {
  resource(id: $id) {
    ...resource
  }
}
    ${ResourceFragmentDoc}`;
export const useResourceQuery = <TData = ResourceQuery, TError = unknown>(variables: ResourceQueryVariables, options?: Omit<UseQueryOptions<ResourceQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<ResourceQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<ResourceQuery, TError, TData>({
    queryKey: ['resource', variables],
    queryFn: fetcher<ResourceQuery, ResourceQueryVariables>(ResourceDocument, variables),
    ...options
  });
};
export const ResourcesByOrgIdDocument = `
    query resourcesByOrgId($orgId: String!) {
  resourcesByOrgId(orgId: $orgId) {
    ...resource
  }
}
    ${ResourceFragmentDoc}`;
export const useResourcesByOrgIdQuery = <TData = ResourcesByOrgIdQuery, TError = unknown>(variables: ResourcesByOrgIdQueryVariables, options?: Omit<UseQueryOptions<ResourcesByOrgIdQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<ResourcesByOrgIdQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<ResourcesByOrgIdQuery, TError, TData>({
    queryKey: ['resourcesByOrgId', variables],
    queryFn: fetcher<ResourcesByOrgIdQuery, ResourcesByOrgIdQueryVariables>(ResourcesByOrgIdDocument, variables),
    ...options
  });
};
export const GetPrimaryResourcesDocument = `
    query getPrimaryResources {
  getPrimaryResources {
    ...resource
  }
}
    ${ResourceFragmentDoc}`;
export const useGetPrimaryResourcesQuery = <TData = GetPrimaryResourcesQuery, TError = unknown>(variables?: GetPrimaryResourcesQueryVariables, options?: Omit<UseQueryOptions<GetPrimaryResourcesQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<GetPrimaryResourcesQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<GetPrimaryResourcesQuery, TError, TData>({
    queryKey: variables === undefined ? ['getPrimaryResources'] : ['getPrimaryResources', variables],
    queryFn: fetcher<GetPrimaryResourcesQuery, GetPrimaryResourcesQueryVariables>(GetPrimaryResourcesDocument, variables),
    ...options
  });
};
export const CreateResourceDocument = `
    mutation createResource($name: String!, $data: ResourceInput!) {
  createResource(name: $name, data: $data) {
    ...resource
  }
}
    ${ResourceFragmentDoc}`;
export const useCreateResourceMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreateResourceMutation, TError, CreateResourceMutationVariables, TContext>) => {
  return useMutation<CreateResourceMutation, TError, CreateResourceMutationVariables, TContext>({
    mutationKey: ['createResource'],
    mutationFn: (variables?: CreateResourceMutationVariables) => fetcher<CreateResourceMutation, CreateResourceMutationVariables>(CreateResourceDocument, variables)(),
    ...options
  });
};
export const UpdateResourceDocument = `
    mutation updateResource($id: String!, $data: ResourceInput!) {
  updateResource(id: $id, data: $data) {
    ...resource
  }
}
    ${ResourceFragmentDoc}`;
export const useUpdateResourceMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateResourceMutation, TError, UpdateResourceMutationVariables, TContext>) => {
  return useMutation<UpdateResourceMutation, TError, UpdateResourceMutationVariables, TContext>({
    mutationKey: ['updateResource'],
    mutationFn: (variables?: UpdateResourceMutationVariables) => fetcher<UpdateResourceMutation, UpdateResourceMutationVariables>(UpdateResourceDocument, variables)(),
    ...options
  });
};
export const DeleteResourceDocument = `
    mutation deleteResource($id: String!) {
  deleteResource(id: $id) {
    ...resource
  }
}
    ${ResourceFragmentDoc}`;
export const useDeleteResourceMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<DeleteResourceMutation, TError, DeleteResourceMutationVariables, TContext>) => {
  return useMutation<DeleteResourceMutation, TError, DeleteResourceMutationVariables, TContext>({
    mutationKey: ['deleteResource'],
    mutationFn: (variables?: DeleteResourceMutationVariables) => fetcher<DeleteResourceMutation, DeleteResourceMutationVariables>(DeleteResourceDocument, variables)(),
    ...options
  });
};
export const UserLessonAnswerDocument = `
    query userLessonAnswer($userId: String!, $progressId: String!, $questionId: String!) {
  userLessonAnswer(
    userId: $userId
    progressId: $progressId
    questionId: $questionId
  ) {
    ...userLessonAnswer
  }
}
    ${UserLessonAnswerFragmentDoc}`;
export const useUserLessonAnswerQuery = <TData = UserLessonAnswerQuery, TError = unknown>(variables: UserLessonAnswerQueryVariables, options?: Omit<UseQueryOptions<UserLessonAnswerQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<UserLessonAnswerQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<UserLessonAnswerQuery, TError, TData>({
    queryKey: ['userLessonAnswer', variables],
    queryFn: fetcher<UserLessonAnswerQuery, UserLessonAnswerQueryVariables>(UserLessonAnswerDocument, variables),
    ...options
  });
};
export const UserLessonAnswersByCompoundIdDocument = `
    query userLessonAnswersByCompoundId($userId: String!, $progressId: String!, $questionIds: [String!]!) {
  userLessonAnswersByCompoundId(
    userId: $userId
    progressId: $progressId
    questionIds: $questionIds
  ) {
    ...userLessonAnswer
  }
}
    ${UserLessonAnswerFragmentDoc}`;
export const useUserLessonAnswersByCompoundIdQuery = <TData = UserLessonAnswersByCompoundIdQuery, TError = unknown>(variables: UserLessonAnswersByCompoundIdQueryVariables, options?: Omit<UseQueryOptions<UserLessonAnswersByCompoundIdQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<UserLessonAnswersByCompoundIdQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<UserLessonAnswersByCompoundIdQuery, TError, TData>({
    queryKey: ['userLessonAnswersByCompoundId', variables],
    queryFn: fetcher<UserLessonAnswersByCompoundIdQuery, UserLessonAnswersByCompoundIdQueryVariables>(UserLessonAnswersByCompoundIdDocument, variables),
    ...options
  });
};
export const MetricsDocument = `
    query metrics($orgId: String, $groups: [String!], $startDate: String, $endDate: String) {
  metrics(
    orgId: $orgId
    groups: $groups
    startDate: $startDate
    endDate: $endDate
  ) {
    ...metrics
  }
}
    ${MetricsFragmentDoc}`;
export const useMetricsQuery = <TData = MetricsQuery, TError = unknown>(variables?: MetricsQueryVariables, options?: Omit<UseQueryOptions<MetricsQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<MetricsQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<MetricsQuery, TError, TData>({
    queryKey: variables === undefined ? ['metrics'] : ['metrics', variables],
    queryFn: fetcher<MetricsQuery, MetricsQueryVariables>(MetricsDocument, variables),
    ...options
  });
};
export const PrePostMetricsDocument = `
    query prePostMetrics($orgId: String, $groups: [String!], $startDate: String, $endDate: String) {
  prePostMetrics(
    orgId: $orgId
    groups: $groups
    startDate: $startDate
    endDate: $endDate
  ) {
    ...prePostMetrics
  }
}
    ${PrePostMetricsFragmentDoc}`;
export const usePrePostMetricsQuery = <TData = PrePostMetricsQuery, TError = unknown>(variables?: PrePostMetricsQueryVariables, options?: Omit<UseQueryOptions<PrePostMetricsQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<PrePostMetricsQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<PrePostMetricsQuery, TError, TData>({
    queryKey: variables === undefined ? ['prePostMetrics'] : ['prePostMetrics', variables],
    queryFn: fetcher<PrePostMetricsQuery, PrePostMetricsQueryVariables>(PrePostMetricsDocument, variables),
    ...options
  });
};
export const UpdateUserLessonAnswerDocument = `
    mutation updateUserLessonAnswer($userId: String!, $orgId: String, $progressId: String!, $questionId: String!, $answerPayload: JSON!) {
  updateUserLessonAnswer(
    userId: $userId
    orgId: $orgId
    progressId: $progressId
    questionId: $questionId
    answerPayload: $answerPayload
  ) {
    ...userLessonAnswer
  }
}
    ${UserLessonAnswerFragmentDoc}`;
export const useUpdateUserLessonAnswerMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateUserLessonAnswerMutation, TError, UpdateUserLessonAnswerMutationVariables, TContext>) => {
  return useMutation<UpdateUserLessonAnswerMutation, TError, UpdateUserLessonAnswerMutationVariables, TContext>({
    mutationKey: ['updateUserLessonAnswer'],
    mutationFn: (variables?: UpdateUserLessonAnswerMutationVariables) => fetcher<UpdateUserLessonAnswerMutation, UpdateUserLessonAnswerMutationVariables>(UpdateUserLessonAnswerDocument, variables)(),
    ...options
  });
};
export const UserLessonProgressDocument = `
    query userLessonProgress($userId: String!) {
  userLessonProgress(userId: $userId) {
    ...userLessonProgress
  }
}
    ${UserLessonProgressFragmentDoc}`;
export const useUserLessonProgressQuery = <TData = UserLessonProgressQuery, TError = unknown>(variables: UserLessonProgressQueryVariables, options?: Omit<UseQueryOptions<UserLessonProgressQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<UserLessonProgressQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<UserLessonProgressQuery, TError, TData>({
    queryKey: ['userLessonProgress', variables],
    queryFn: fetcher<UserLessonProgressQuery, UserLessonProgressQueryVariables>(UserLessonProgressDocument, variables),
    ...options
  });
};
export const CreateUserLessonProgressDocument = `
    mutation createUserLessonProgress($userId: String!, $orgId: String) {
  createUserLessonProgress(userId: $userId, orgId: $orgId) {
    ...userLessonProgress
  }
}
    ${UserLessonProgressFragmentDoc}`;
export const useCreateUserLessonProgressMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreateUserLessonProgressMutation, TError, CreateUserLessonProgressMutationVariables, TContext>) => {
  return useMutation<CreateUserLessonProgressMutation, TError, CreateUserLessonProgressMutationVariables, TContext>({
    mutationKey: ['createUserLessonProgress'],
    mutationFn: (variables?: CreateUserLessonProgressMutationVariables) => fetcher<CreateUserLessonProgressMutation, CreateUserLessonProgressMutationVariables>(CreateUserLessonProgressDocument, variables)(),
    ...options
  });
};
export const CompleteSectionForUserLessonProgressDocument = `
    mutation completeSectionForUserLessonProgress($userId: String!, $sectionId: String!) {
  completeSectionForUserLessonProgress(userId: $userId, sectionId: $sectionId) {
    ...userLessonProgress
  }
}
    ${UserLessonProgressFragmentDoc}`;
export const useCompleteSectionForUserLessonProgressMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CompleteSectionForUserLessonProgressMutation, TError, CompleteSectionForUserLessonProgressMutationVariables, TContext>) => {
  return useMutation<CompleteSectionForUserLessonProgressMutation, TError, CompleteSectionForUserLessonProgressMutationVariables, TContext>({
    mutationKey: ['completeSectionForUserLessonProgress'],
    mutationFn: (variables?: CompleteSectionForUserLessonProgressMutationVariables) => fetcher<CompleteSectionForUserLessonProgressMutation, CompleteSectionForUserLessonProgressMutationVariables>(CompleteSectionForUserLessonProgressDocument, variables)(),
    ...options
  });
};
export const MarkProgressAsCompleteDocument = `
    mutation markProgressAsComplete($userId: String!) {
  markProgressAsComplete(userId: $userId) {
    ...userLessonProgress
  }
}
    ${UserLessonProgressFragmentDoc}`;
export const useMarkProgressAsCompleteMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<MarkProgressAsCompleteMutation, TError, MarkProgressAsCompleteMutationVariables, TContext>) => {
  return useMutation<MarkProgressAsCompleteMutation, TError, MarkProgressAsCompleteMutationVariables, TContext>({
    mutationKey: ['markProgressAsComplete'],
    mutationFn: (variables?: MarkProgressAsCompleteMutationVariables) => fetcher<MarkProgressAsCompleteMutation, MarkProgressAsCompleteMutationVariables>(MarkProgressAsCompleteDocument, variables)(),
    ...options
  });
};
export const CreateUserLessonProgressesDocument = `
    mutation createUserLessonProgresses($userIds: [String!]!, $orgId: String) {
  createUserLessonProgresses(userIds: $userIds, orgId: $orgId)
}
    `;
export const useCreateUserLessonProgressesMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreateUserLessonProgressesMutation, TError, CreateUserLessonProgressesMutationVariables, TContext>) => {
  return useMutation<CreateUserLessonProgressesMutation, TError, CreateUserLessonProgressesMutationVariables, TContext>({
    mutationKey: ['createUserLessonProgresses'],
    mutationFn: (variables?: CreateUserLessonProgressesMutationVariables) => fetcher<CreateUserLessonProgressesMutation, CreateUserLessonProgressesMutationVariables>(CreateUserLessonProgressesDocument, variables)(),
    ...options
  });
};
export const GetOrganizationsByUserIdDocument = `
    query getOrganizationsByUserId($userId: String!) {
  getOrganizationsByUserId(userId: $userId) {
    ...org
  }
}
    ${OrgFragmentDoc}`;
export const useGetOrganizationsByUserIdQuery = <TData = GetOrganizationsByUserIdQuery, TError = unknown>(variables: GetOrganizationsByUserIdQueryVariables, options?: Omit<UseQueryOptions<GetOrganizationsByUserIdQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<GetOrganizationsByUserIdQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<GetOrganizationsByUserIdQuery, TError, TData>({
    queryKey: ['getOrganizationsByUserId', variables],
    queryFn: fetcher<GetOrganizationsByUserIdQuery, GetOrganizationsByUserIdQueryVariables>(GetOrganizationsByUserIdDocument, variables),
    ...options
  });
};
export const StudentOrgsJoinedAndInvitedDocument = `
    query studentOrgsJoinedAndInvited($userEmail: String!) {
  studentOrgsjoinedAndInvited(userEmail: $userEmail) {
    ...organizationOfStudent
  }
}
    ${OrganizationOfStudentFragmentDoc}`;
export const useStudentOrgsJoinedAndInvitedQuery = <TData = StudentOrgsJoinedAndInvitedQuery, TError = unknown>(variables: StudentOrgsJoinedAndInvitedQueryVariables, options?: Omit<UseQueryOptions<StudentOrgsJoinedAndInvitedQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<StudentOrgsJoinedAndInvitedQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<StudentOrgsJoinedAndInvitedQuery, TError, TData>({
    queryKey: ['studentOrgsJoinedAndInvited', variables],
    queryFn: fetcher<StudentOrgsJoinedAndInvitedQuery, StudentOrgsJoinedAndInvitedQueryVariables>(StudentOrgsJoinedAndInvitedDocument, variables),
    ...options
  });
};
export const UpdateUserOrganizationDocument = `
    mutation updateUserOrganization($id: String!, $data: UserOrganizationInput!) {
  updateUserOrganization(id: $id, data: $data) {
    ...userOrganization
  }
}
    ${UserOrganizationFragmentDoc}`;
export const useUpdateUserOrganizationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateUserOrganizationMutation, TError, UpdateUserOrganizationMutationVariables, TContext>) => {
  return useMutation<UpdateUserOrganizationMutation, TError, UpdateUserOrganizationMutationVariables, TContext>({
    mutationKey: ['updateUserOrganization'],
    mutationFn: (variables?: UpdateUserOrganizationMutationVariables) => fetcher<UpdateUserOrganizationMutation, UpdateUserOrganizationMutationVariables>(UpdateUserOrganizationDocument, variables)(),
    ...options
  });
};
export const CreateUserOrganizationDocument = `
    mutation createUserOrganization($data: UserOrganizationInput!) {
  userOrganization: createUserOrganization(data: $data) {
    ...userOrganization
  }
}
    ${UserOrganizationFragmentDoc}`;
export const useCreateUserOrganizationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreateUserOrganizationMutation, TError, CreateUserOrganizationMutationVariables, TContext>) => {
  return useMutation<CreateUserOrganizationMutation, TError, CreateUserOrganizationMutationVariables, TContext>({
    mutationKey: ['createUserOrganization'],
    mutationFn: (variables?: CreateUserOrganizationMutationVariables) => fetcher<CreateUserOrganizationMutation, CreateUserOrganizationMutationVariables>(CreateUserOrganizationDocument, variables)(),
    ...options
  });
};
export const DeleteUserOrganizationDocument = `
    mutation deleteUserOrganization($id: String!) {
  deleteUserOrganization(id: $id) {
    ...userOrganization
  }
}
    ${UserOrganizationFragmentDoc}`;
export const useDeleteUserOrganizationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<DeleteUserOrganizationMutation, TError, DeleteUserOrganizationMutationVariables, TContext>) => {
  return useMutation<DeleteUserOrganizationMutation, TError, DeleteUserOrganizationMutationVariables, TContext>({
    mutationKey: ['deleteUserOrganization'],
    mutationFn: (variables?: DeleteUserOrganizationMutationVariables) => fetcher<DeleteUserOrganizationMutation, DeleteUserOrganizationMutationVariables>(DeleteUserOrganizationDocument, variables)(),
    ...options
  });
};
export const DeleteAllOrganizationsOfUserDocument = `
    mutation deleteAllOrganizationsOfUser($userId: String!) {
  deleteAllOrganizationsOfUser(userId: $userId)
}
    `;
export const useDeleteAllOrganizationsOfUserMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<DeleteAllOrganizationsOfUserMutation, TError, DeleteAllOrganizationsOfUserMutationVariables, TContext>) => {
  return useMutation<DeleteAllOrganizationsOfUserMutation, TError, DeleteAllOrganizationsOfUserMutationVariables, TContext>({
    mutationKey: ['deleteAllOrganizationsOfUser'],
    mutationFn: (variables?: DeleteAllOrganizationsOfUserMutationVariables) => fetcher<DeleteAllOrganizationsOfUserMutation, DeleteAllOrganizationsOfUserMutationVariables>(DeleteAllOrganizationsOfUserDocument, variables)(),
    ...options
  });
};
export const JoinSecondaryOrganizationDocument = `
    mutation joinSecondaryOrganization($userEmail: String!, $userId: String!, $orgId: String!) {
  joinSecondaryOrganization(userEmail: $userEmail, userId: $userId, orgId: $orgId) {
    ...organizationOfStudent
  }
}
    ${OrganizationOfStudentFragmentDoc}`;
export const useJoinSecondaryOrganizationMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<JoinSecondaryOrganizationMutation, TError, JoinSecondaryOrganizationMutationVariables, TContext>) => {
  return useMutation<JoinSecondaryOrganizationMutation, TError, JoinSecondaryOrganizationMutationVariables, TContext>({
    mutationKey: ['joinSecondaryOrganization'],
    mutationFn: (variables?: JoinSecondaryOrganizationMutationVariables) => fetcher<JoinSecondaryOrganizationMutation, JoinSecondaryOrganizationMutationVariables>(JoinSecondaryOrganizationDocument, variables)(),
    ...options
  });
};
export const UserPingsDocument = `
    query userPings($userId: String!) {
  userPings(userId: $userId) {
    ...userPing
  }
}
    ${UserPingFragmentDoc}`;
export const useUserPingsQuery = <TData = UserPingsQuery, TError = unknown>(variables: UserPingsQueryVariables, options?: Omit<UseQueryOptions<UserPingsQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<UserPingsQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<UserPingsQuery, TError, TData>({
    queryKey: ['userPings', variables],
    queryFn: fetcher<UserPingsQuery, UserPingsQueryVariables>(UserPingsDocument, variables),
    ...options
  });
};
export const CreatePingDocument = `
    mutation createPing($data: UserPingInfo!) {
  userPing: createPing(data: $data) {
    ...userPing
  }
}
    ${UserPingFragmentDoc}`;
export const useCreatePingMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreatePingMutation, TError, CreatePingMutationVariables, TContext>) => {
  return useMutation<CreatePingMutation, TError, CreatePingMutationVariables, TContext>({
    mutationKey: ['createPing'],
    mutationFn: (variables?: CreatePingMutationVariables) => fetcher<CreatePingMutation, CreatePingMutationVariables>(CreatePingDocument, variables)(),
    ...options
  });
};
export const UserDocument = `
    query user($id: String!) {
  user(id: $id) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useUserQuery = <TData = UserQuery, TError = unknown>(variables: UserQueryVariables, options?: Omit<UseQueryOptions<UserQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<UserQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<UserQuery, TError, TData>({
    queryKey: ['user', variables],
    queryFn: fetcher<UserQuery, UserQueryVariables>(UserDocument, variables),
    ...options
  });
};
export const UsersDocument = `
    query users($ids: [String!]!) {
  users(ids: $ids) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useUsersQuery = <TData = UsersQuery, TError = unknown>(variables: UsersQueryVariables, options?: Omit<UseQueryOptions<UsersQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<UsersQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<UsersQuery, TError, TData>({
    queryKey: ['users', variables],
    queryFn: fetcher<UsersQuery, UsersQueryVariables>(UsersDocument, variables),
    ...options
  });
};
export const UserByEmailDocument = `
    query userByEmail($email: String!) {
  userByEmail(email: $email) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useUserByEmailQuery = <TData = UserByEmailQuery, TError = unknown>(variables: UserByEmailQueryVariables, options?: Omit<UseQueryOptions<UserByEmailQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<UserByEmailQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<UserByEmailQuery, TError, TData>({
    queryKey: ['userByEmail', variables],
    queryFn: fetcher<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, variables),
    ...options
  });
};
export const InvitedByEmailDocument = `
    query invitedByEmail($email: String!) {
  invitedByEmail(email: $email) {
    ...invite
  }
}
    ${InviteFragmentDoc}`;
export const useInvitedByEmailQuery = <TData = InvitedByEmailQuery, TError = unknown>(variables: InvitedByEmailQueryVariables, options?: Omit<UseQueryOptions<InvitedByEmailQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<InvitedByEmailQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<InvitedByEmailQuery, TError, TData>({
    queryKey: ['invitedByEmail', variables],
    queryFn: fetcher<InvitedByEmailQuery, InvitedByEmailQueryVariables>(InvitedByEmailDocument, variables),
    ...options
  });
};
export const UserInvitesDocument = `
    query userInvites($emails: [String!]!) {
  userInvites(emails: $emails) {
    ...invite
  }
}
    ${InviteFragmentDoc}`;
export const useUserInvitesQuery = <TData = UserInvitesQuery, TError = unknown>(variables: UserInvitesQueryVariables, options?: Omit<UseQueryOptions<UserInvitesQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<UserInvitesQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<UserInvitesQuery, TError, TData>({
    queryKey: ['userInvites', variables],
    queryFn: fetcher<UserInvitesQuery, UserInvitesQueryVariables>(UserInvitesDocument, variables),
    ...options
  });
};
export const AllStudentsDocument = `
    query allStudents {
  allStudents {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useAllStudentsQuery = <TData = AllStudentsQuery, TError = unknown>(variables?: AllStudentsQueryVariables, options?: Omit<UseQueryOptions<AllStudentsQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<AllStudentsQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<AllStudentsQuery, TError, TData>({
    queryKey: variables === undefined ? ['allStudents'] : ['allStudents', variables],
    queryFn: fetcher<AllStudentsQuery, AllStudentsQueryVariables>(AllStudentsDocument, variables),
    ...options
  });
};
export const AllOrgOwnersDocument = `
    query allOrgOwners {
  allOrgOwners {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useAllOrgOwnersQuery = <TData = AllOrgOwnersQuery, TError = unknown>(variables?: AllOrgOwnersQueryVariables, options?: Omit<UseQueryOptions<AllOrgOwnersQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<AllOrgOwnersQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<AllOrgOwnersQuery, TError, TData>({
    queryKey: variables === undefined ? ['allOrgOwners'] : ['allOrgOwners', variables],
    queryFn: fetcher<AllOrgOwnersQuery, AllOrgOwnersQueryVariables>(AllOrgOwnersDocument, variables),
    ...options
  });
};
export const AllOrgManagersDocument = `
    query allOrgManagers {
  allOrgManagers {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useAllOrgManagersQuery = <TData = AllOrgManagersQuery, TError = unknown>(variables?: AllOrgManagersQueryVariables, options?: Omit<UseQueryOptions<AllOrgManagersQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<AllOrgManagersQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<AllOrgManagersQuery, TError, TData>({
    queryKey: variables === undefined ? ['allOrgManagers'] : ['allOrgManagers', variables],
    queryFn: fetcher<AllOrgManagersQuery, AllOrgManagersQueryVariables>(AllOrgManagersDocument, variables),
    ...options
  });
};
export const RegisteredAndInvitedStudentsByOrganizationDocument = `
    query registeredAndInvitedStudentsByOrganization($orgId: String!, $searchInput: String, $sortingInput: SortingKey, $paginationData: PaginationInput, $groups: [String!]) {
  registeredAndInvitedStudentsByOrganization(
    orgId: $orgId
    searchInput: $searchInput
    sortingInput: $sortingInput
    paginationData: $paginationData
    groups: $groups
  ) {
    ...paginatedUserList
  }
}
    ${PaginatedUserListFragmentDoc}`;
export const useRegisteredAndInvitedStudentsByOrganizationQuery = <TData = RegisteredAndInvitedStudentsByOrganizationQuery, TError = unknown>(variables: RegisteredAndInvitedStudentsByOrganizationQueryVariables, options?: Omit<UseQueryOptions<RegisteredAndInvitedStudentsByOrganizationQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<RegisteredAndInvitedStudentsByOrganizationQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<RegisteredAndInvitedStudentsByOrganizationQuery, TError, TData>({
    queryKey: ['registeredAndInvitedStudentsByOrganization', variables],
    queryFn: fetcher<RegisteredAndInvitedStudentsByOrganizationQuery, RegisteredAndInvitedStudentsByOrganizationQueryVariables>(RegisteredAndInvitedStudentsByOrganizationDocument, variables),
    ...options
  });
};
export const RegisteredAndInvitedAdminsByOrganizationDocument = `
    query registeredAndInvitedAdminsByOrganization($orgId: String!, $searchInput: String, $sortingInput: SortingKey, $paginationData: PaginationInput) {
  registeredAndInvitedAdminsByOrganization(
    orgId: $orgId
    searchInput: $searchInput
    sortingInput: $sortingInput
    paginationData: $paginationData
  ) {
    ...paginatedUserList
  }
}
    ${PaginatedUserListFragmentDoc}`;
export const useRegisteredAndInvitedAdminsByOrganizationQuery = <TData = RegisteredAndInvitedAdminsByOrganizationQuery, TError = unknown>(variables: RegisteredAndInvitedAdminsByOrganizationQueryVariables, options?: Omit<UseQueryOptions<RegisteredAndInvitedAdminsByOrganizationQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<RegisteredAndInvitedAdminsByOrganizationQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<RegisteredAndInvitedAdminsByOrganizationQuery, TError, TData>({
    queryKey: ['registeredAndInvitedAdminsByOrganization', variables],
    queryFn: fetcher<RegisteredAndInvitedAdminsByOrganizationQuery, RegisteredAndInvitedAdminsByOrganizationQueryVariables>(RegisteredAndInvitedAdminsByOrganizationDocument, variables),
    ...options
  });
};
export const AllRegisteredAndInvitedOrgAdminsDocument = `
    query allRegisteredAndInvitedOrgAdmins($searchInput: String, $sortingInput: SortingKey, $paginationData: PaginationInput, $filters: [String!]) {
  allRegisteredAndInvitedOrgAdmins(
    searchInput: $searchInput
    sortingInput: $sortingInput
    paginationData: $paginationData
    filters: $filters
  ) {
    ...paginatedUserList
  }
}
    ${PaginatedUserListFragmentDoc}`;
export const useAllRegisteredAndInvitedOrgAdminsQuery = <TData = AllRegisteredAndInvitedOrgAdminsQuery, TError = unknown>(variables?: AllRegisteredAndInvitedOrgAdminsQueryVariables, options?: Omit<UseQueryOptions<AllRegisteredAndInvitedOrgAdminsQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<AllRegisteredAndInvitedOrgAdminsQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<AllRegisteredAndInvitedOrgAdminsQuery, TError, TData>({
    queryKey: variables === undefined ? ['allRegisteredAndInvitedOrgAdmins'] : ['allRegisteredAndInvitedOrgAdmins', variables],
    queryFn: fetcher<AllRegisteredAndInvitedOrgAdminsQuery, AllRegisteredAndInvitedOrgAdminsQueryVariables>(AllRegisteredAndInvitedOrgAdminsDocument, variables),
    ...options
  });
};
export const AllRegisteredAndInvitedSystemAdminsDocument = `
    query allRegisteredAndInvitedSystemAdmins($searchInput: String, $sortingInput: SortingKey, $paginationData: PaginationInput) {
  allRegisteredAndInvitedSystemAdmins(
    searchInput: $searchInput
    sortingInput: $sortingInput
    paginationData: $paginationData
  ) {
    ...paginatedUserList
  }
}
    ${PaginatedUserListFragmentDoc}`;
export const useAllRegisteredAndInvitedSystemAdminsQuery = <TData = AllRegisteredAndInvitedSystemAdminsQuery, TError = unknown>(variables?: AllRegisteredAndInvitedSystemAdminsQueryVariables, options?: Omit<UseQueryOptions<AllRegisteredAndInvitedSystemAdminsQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<AllRegisteredAndInvitedSystemAdminsQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<AllRegisteredAndInvitedSystemAdminsQuery, TError, TData>({
    queryKey: variables === undefined ? ['allRegisteredAndInvitedSystemAdmins'] : ['allRegisteredAndInvitedSystemAdmins', variables],
    queryFn: fetcher<AllRegisteredAndInvitedSystemAdminsQuery, AllRegisteredAndInvitedSystemAdminsQueryVariables>(AllRegisteredAndInvitedSystemAdminsDocument, variables),
    ...options
  });
};
export const RegisteredAndInvitedUsersDocument = `
    query registeredAndInvitedUsers($ids: [String!]!) {
  registeredAndInvitedUsers(ids: $ids) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useRegisteredAndInvitedUsersQuery = <TData = RegisteredAndInvitedUsersQuery, TError = unknown>(variables: RegisteredAndInvitedUsersQueryVariables, options?: Omit<UseQueryOptions<RegisteredAndInvitedUsersQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<RegisteredAndInvitedUsersQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<RegisteredAndInvitedUsersQuery, TError, TData>({
    queryKey: ['registeredAndInvitedUsers', variables],
    queryFn: fetcher<RegisteredAndInvitedUsersQuery, RegisteredAndInvitedUsersQueryVariables>(RegisteredAndInvitedUsersDocument, variables),
    ...options
  });
};
export const FilteredStudentsForExcelDocument = `
    query filteredStudentsForExcel($orgId: String, $groups: [String!], $startDate: String, $endDate: String) {
  filteredStudentsForExcel(
    orgId: $orgId
    groups: $groups
    startDate: $startDate
    endDate: $endDate
  ) {
    ...studentForExcel
  }
}
    ${StudentForExcelFragmentDoc}`;
export const useFilteredStudentsForExcelQuery = <TData = FilteredStudentsForExcelQuery, TError = unknown>(variables?: FilteredStudentsForExcelQueryVariables, options?: Omit<UseQueryOptions<FilteredStudentsForExcelQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<FilteredStudentsForExcelQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<FilteredStudentsForExcelQuery, TError, TData>({
    queryKey: variables === undefined ? ['filteredStudentsForExcel'] : ['filteredStudentsForExcel', variables],
    queryFn: fetcher<FilteredStudentsForExcelQuery, FilteredStudentsForExcelQueryVariables>(FilteredStudentsForExcelDocument, variables),
    ...options
  });
};
export const GetStudentMyGroupFilterDocument = `
    query getStudentMyGroupFilter($orgId: String!) {
  getStudentMyGroupFilter(orgId: $orgId)
}
    `;
export const useGetStudentMyGroupFilterQuery = <TData = GetStudentMyGroupFilterQuery, TError = unknown>(variables: GetStudentMyGroupFilterQueryVariables, options?: Omit<UseQueryOptions<GetStudentMyGroupFilterQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<GetStudentMyGroupFilterQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<GetStudentMyGroupFilterQuery, TError, TData>({
    queryKey: ['getStudentMyGroupFilter', variables],
    queryFn: fetcher<GetStudentMyGroupFilterQuery, GetStudentMyGroupFilterQueryVariables>(GetStudentMyGroupFilterDocument, variables),
    ...options
  });
};
export const CreateUserDocument = `
    mutation createUser($data: CreateUserInput!) {
  createUser(data: $data) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useCreateUserMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>) => {
  return useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>({
    mutationKey: ['createUser'],
    mutationFn: (variables?: CreateUserMutationVariables) => fetcher<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, variables)(),
    ...options
  });
};
export const UpdateUserDocument = `
    mutation updateUser($id: String!, $orgId: String, $data: UpdateUserInput!) {
  updateUser(id: $id, orgId: $orgId, data: $data) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useUpdateUserMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) => {
  return useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>({
    mutationKey: ['updateUser'],
    mutationFn: (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
    ...options
  });
};
export const UpdateUsersDocument = `
    mutation updateUsers($ids: [String!]!, $orgId: String, $groupPush: Boolean, $data: UpdateUserInput!) {
  updateUsers(ids: $ids, orgId: $orgId, groupPush: $groupPush, data: $data)
}
    `;
export const useUpdateUsersMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateUsersMutation, TError, UpdateUsersMutationVariables, TContext>) => {
  return useMutation<UpdateUsersMutation, TError, UpdateUsersMutationVariables, TContext>({
    mutationKey: ['updateUsers'],
    mutationFn: (variables?: UpdateUsersMutationVariables) => fetcher<UpdateUsersMutation, UpdateUsersMutationVariables>(UpdateUsersDocument, variables)(),
    ...options
  });
};
export const UpdateInviteDocument = `
    mutation updateInvite($email: String!, $orgId: String, $data: UpdateInviteInput!) {
  updateInvite(email: $email, orgId: $orgId, data: $data) {
    ...invite
  }
}
    ${InviteFragmentDoc}`;
export const useUpdateInviteMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateInviteMutation, TError, UpdateInviteMutationVariables, TContext>) => {
  return useMutation<UpdateInviteMutation, TError, UpdateInviteMutationVariables, TContext>({
    mutationKey: ['updateInvite'],
    mutationFn: (variables?: UpdateInviteMutationVariables) => fetcher<UpdateInviteMutation, UpdateInviteMutationVariables>(UpdateInviteDocument, variables)(),
    ...options
  });
};
export const UpdateInvitesDocument = `
    mutation updateInvites($emails: [String!]!, $orgId: String, $groupPush: Boolean, $data: UpdateInviteInput!) {
  updateInvites(
    emails: $emails
    orgId: $orgId
    groupPush: $groupPush
    data: $data
  )
}
    `;
export const useUpdateInvitesMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<UpdateInvitesMutation, TError, UpdateInvitesMutationVariables, TContext>) => {
  return useMutation<UpdateInvitesMutation, TError, UpdateInvitesMutationVariables, TContext>({
    mutationKey: ['updateInvites'],
    mutationFn: (variables?: UpdateInvitesMutationVariables) => fetcher<UpdateInvitesMutation, UpdateInvitesMutationVariables>(UpdateInvitesDocument, variables)(),
    ...options
  });
};
export const DeleteUserDocument = `
    mutation deleteUser($id: String!, $orgId: String) {
  deleteUser(id: $id, orgId: $orgId) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useDeleteUserMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>) => {
  return useMutation<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>({
    mutationKey: ['deleteUser'],
    mutationFn: (variables?: DeleteUserMutationVariables) => fetcher<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, variables)(),
    ...options
  });
};
export const DeleteUsersDocument = `
    mutation deleteUsers($ids: [String!]!, $orgId: String) {
  deleteUsers(ids: $ids, orgId: $orgId)
}
    `;
export const useDeleteUsersMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<DeleteUsersMutation, TError, DeleteUsersMutationVariables, TContext>) => {
  return useMutation<DeleteUsersMutation, TError, DeleteUsersMutationVariables, TContext>({
    mutationKey: ['deleteUsers'],
    mutationFn: (variables?: DeleteUsersMutationVariables) => fetcher<DeleteUsersMutation, DeleteUsersMutationVariables>(DeleteUsersDocument, variables)(),
    ...options
  });
};
export const RestoreUserDocument = `
    mutation restoreUser($id: String!, $orgId: String) {
  restoreUser(id: $id, orgId: $orgId) {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const useRestoreUserMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<RestoreUserMutation, TError, RestoreUserMutationVariables, TContext>) => {
  return useMutation<RestoreUserMutation, TError, RestoreUserMutationVariables, TContext>({
    mutationKey: ['restoreUser'],
    mutationFn: (variables?: RestoreUserMutationVariables) => fetcher<RestoreUserMutation, RestoreUserMutationVariables>(RestoreUserDocument, variables)(),
    ...options
  });
};
export const RestoreUsersDocument = `
    mutation restoreUsers($ids: [String!]!, $orgId: String) {
  restoreUsers(ids: $ids, orgId: $orgId)
}
    `;
export const useRestoreUsersMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<RestoreUsersMutation, TError, RestoreUsersMutationVariables, TContext>) => {
  return useMutation<RestoreUsersMutation, TError, RestoreUsersMutationVariables, TContext>({
    mutationKey: ['restoreUsers'],
    mutationFn: (variables?: RestoreUsersMutationVariables) => fetcher<RestoreUsersMutation, RestoreUsersMutationVariables>(RestoreUsersDocument, variables)(),
    ...options
  });
};
export const CreateInviteDocument = `
    mutation createInvite($user: InviteInput!, $sendEmail: Boolean) {
  createInvite(user: $user, sendEmail: $sendEmail) {
    ...invite
  }
}
    ${InviteFragmentDoc}`;
export const useCreateInviteMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreateInviteMutation, TError, CreateInviteMutationVariables, TContext>) => {
  return useMutation<CreateInviteMutation, TError, CreateInviteMutationVariables, TContext>({
    mutationKey: ['createInvite'],
    mutationFn: (variables?: CreateInviteMutationVariables) => fetcher<CreateInviteMutation, CreateInviteMutationVariables>(CreateInviteDocument, variables)(),
    ...options
  });
};
export const CreateManyInvitesDocument = `
    mutation createManyInvites($users: [InviteInput!]!) {
  createManyInvites(users: $users) {
    ...invite
  }
}
    ${InviteFragmentDoc}`;
export const useCreateManyInvitesMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreateManyInvitesMutation, TError, CreateManyInvitesMutationVariables, TContext>) => {
  return useMutation<CreateManyInvitesMutation, TError, CreateManyInvitesMutationVariables, TContext>({
    mutationKey: ['createManyInvites'],
    mutationFn: (variables?: CreateManyInvitesMutationVariables) => fetcher<CreateManyInvitesMutation, CreateManyInvitesMutationVariables>(CreateManyInvitesDocument, variables)(),
    ...options
  });
};
export const DeleteInviteDocument = `
    mutation deleteInvite($email: String!, $orgId: String) {
  deleteInvite(email: $email, orgId: $orgId) {
    ...invite
  }
}
    ${InviteFragmentDoc}`;
export const useDeleteInviteMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<DeleteInviteMutation, TError, DeleteInviteMutationVariables, TContext>) => {
  return useMutation<DeleteInviteMutation, TError, DeleteInviteMutationVariables, TContext>({
    mutationKey: ['deleteInvite'],
    mutationFn: (variables?: DeleteInviteMutationVariables) => fetcher<DeleteInviteMutation, DeleteInviteMutationVariables>(DeleteInviteDocument, variables)(),
    ...options
  });
};
export const DeleteInvitesDocument = `
    mutation deleteInvites($emails: [String!]!, $orgId: String) {
  deleteInvites(emails: $emails, orgId: $orgId)
}
    `;
export const useDeleteInvitesMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<DeleteInvitesMutation, TError, DeleteInvitesMutationVariables, TContext>) => {
  return useMutation<DeleteInvitesMutation, TError, DeleteInvitesMutationVariables, TContext>({
    mutationKey: ['deleteInvites'],
    mutationFn: (variables?: DeleteInvitesMutationVariables) => fetcher<DeleteInvitesMutation, DeleteInvitesMutationVariables>(DeleteInvitesDocument, variables)(),
    ...options
  });
};
export const GetLatestVerificationRequestDocument = `
    query getLatestVerificationRequest($identifier: String!) {
  getLatestVerificationRequest(identifier: $identifier) {
    ...verificationRequest
  }
}
    ${VerificationRequestFragmentDoc}`;
export const useGetLatestVerificationRequestQuery = <TData = GetLatestVerificationRequestQuery, TError = unknown>(variables: GetLatestVerificationRequestQueryVariables, options?: Omit<UseQueryOptions<GetLatestVerificationRequestQuery, TError, TData>, 'queryKey'> & {
  queryKey?: UseQueryOptions<GetLatestVerificationRequestQuery, TError, TData>['queryKey'];
}) => {
  return useQuery<GetLatestVerificationRequestQuery, TError, TData>({
    queryKey: ['getLatestVerificationRequest', variables],
    queryFn: fetcher<GetLatestVerificationRequestQuery, GetLatestVerificationRequestQueryVariables>(GetLatestVerificationRequestDocument, variables),
    ...options
  });
};
export const CreateVerificationRequestDocument = `
    mutation createVerificationRequest($identifier: String!, $token: String!, $expires: DateTime!) {
  createVerificationRequest(
    identifier: $identifier
    token: $token
    expires: $expires
  ) {
    ...verificationRequest
  }
}
    ${VerificationRequestFragmentDoc}`;
export const useCreateVerificationRequestMutation = <TError = unknown, TContext = unknown>(options?: UseMutationOptions<CreateVerificationRequestMutation, TError, CreateVerificationRequestMutationVariables, TContext>) => {
  return useMutation<CreateVerificationRequestMutation, TError, CreateVerificationRequestMutationVariables, TContext>({
    mutationKey: ['createVerificationRequest'],
    mutationFn: (variables?: CreateVerificationRequestMutationVariables) => fetcher<CreateVerificationRequestMutation, CreateVerificationRequestMutationVariables>(CreateVerificationRequestDocument, variables)(),
    ...options
  });
};